<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.models') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>

      <div class="w-full h-full flex flex-col overflow-hidden">
        <div v-if="modelCount || fetching" class="flex-grow overflow-auto">
          <div class="w-full p-3 sm:p-5 grid gap-8 grid-cols-collection-mobile sm:grid-cols-collection mt-3">
            <ListCard class="h-20 py-2 bg-white rounded-20 cursor-pointer" v-for="model in getProjectModels()" :key="model.model_id" @click="goToModel(model)">
              <div class="flex flex-col w-full h-full">
                <div class="flex pt-2 pl-4 pr-2 items-center text-14 font-500 text-gray-900">
                  {{ model.name }}
                </div>
                <div class="flex pt-1 pl-4 pr-2 items-center flex-grow text-12 font-500 text-gray-900">
                  {{ model.type }}
                </div>
              </div>
            </ListCard>
          </div>
        </div>
        <div v-else class="flex flex-col items-center h-full justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.models.have_no_models') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.models.create_first_model') }}</div>
          <PillButton class="my-3" :text="$t('projects.models.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import CreateProjectModel from '@/components/project/CreateProjectModel';

export default {
  name: 'Models',
  data() {
    return {
      fetching: true,
    };
  },
  computed: {
    ...mapGetters(['getProjectModels']),
    projectId() {
      return this.$route.params.projectId;
    },
    modelCount() {
      return Object.keys(this.getProjectModels()).length;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectModels', 'deleteProjectModel']),
    goToModel(model) {
      this.$router.push({
        name: 'project-model',
        params: {
          projectId: this.projectId,
          modelId: model.model_id,
        },
      });
    },
    onCreateClicked() {
      this.$showModal(CreateProjectModel);
    },
    onEditClicked(model) {
      // this.$showModal(CreateProjectModel, { model });
      this.$router.push({
        name: 'project-model',
        params: {
          projectId: this.projectId,
          modelId: model.model_id,
        },
      });
    },
    onDeleteClicked(model) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('datastore.confirm_remove_model_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.deleteProjectModel({ project_id: this.projectId, model_id: model.model_id });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectModels({ projectId: this.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
