<template>
  <div>
    <template v-if="variable"><VariableChip :name="variable" :index="5" /></template>
    <template v-else
      ><span class="text-red-600 font-700">{{ $t('not_set') }}</span></template
    >
    <span class="text-pink-500 mx-1">{{ $t('equals') }}</span>
    <template v-if="isFunction">
      <span v-if="functionName" class="text-green-600">{{ functionName }}(</span>
      <span v-else class="text-red-600 font-700 ml-1">{{ $t('not_set') }}</span>

      <span v-if="functionArgs.length" style="word-break: break-word">
        <template v-for="(arg, index) in functionArgs">
          <VariableChip v-if="isVariable(arg)" :key="`v-${index}`" style="word-break: normal" :name="getVariableName(arg)" :index="5" />
          <span v-else :key="index" style="word-break: normal">{{ removePipes(arg) }}</span>
          <span v-if="index !== functionArgs.length - 1" :key="index + 'comma'" class="pr-1">,</span>
        </template>
      </span>
      <span v-if="functionName" class="text-green-600">)</span>
    </template>
    <template v-else-if="isExpression">
      <span v-if="value">
        <ExpressionRenderer class="inline" :expression="value" />
      </span>
      <span v-else class="text-red-600 font-700">{{ $t('not_set') }}</span>
    </template>
    <template v-else>
      <span v-if="value" class="overflow-hidden whitespace-nowrap overflow-ellipsis w-250 inline-block" style="margin-bottom: -5px">{{ removePipes(value) }}</span>
      <span v-else class="text-red-600 font-700">{{ $t('not_set') }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EvaluateStatement',
  props: {
    item: {
      type: Object,
    },
    evaluate: Array,
  },
  computed: {
    variable() {
      return this.payload.variable;
    },
    payload() {
      return this.item.payload;
    },
    isFunction() {
      return this.payload.assignment_type === 'function';
    },
    isExpression() {
      return this.payload.assignment_type === 'function';
    },
    functionName() {
      if (!this.isFunction) return '';
      return this.payload?.value?.function;
    },
    functionArgs() {
      return this.payload.value?.arguments;
    },
    value() {
      return this.payload.value;
    },
  },
  methods: {
    getVariableName(variable) {
      return variable.substring(1).slice(0, -1);
    },
    removePipes(str) {
      if (typeof str !== 'string') return str;
      return str.replaceAll('|.|', '.');
    },
    isVariable(str) {
      if (str) {
        return str.toString().startsWith('{') && str.toString().endsWith('}');
      }
      return false;
    },
  },
};
</script>
