<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.email_templates.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <template v-if="templates.length">
          <div v-for="(emailTemplate, index) in templates" :key="`${index}-${emailTemplate.name}`">
            <ListCard class="flex-row mt-3">
              <div class="mr-4 truncate">{{ emailTemplate.name }}</div>
              <IconButton icon="edit" class="ml-auto text-primary" @click="onEditClicked(emailTemplate)"></IconButton>
              <IconButton icon="delete" class="sm:ml-1 text-primary" @click="onDeleteClicked(emailTemplate)"></IconButton>
            </ListCard>
          </div>
        </template>
        <div v-else class="flex flex-col h-full flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.email_templates.have_no_templates') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.email_templates.create_first_template') }}</div>
          <PillButton class="my-3" :text="$t('projects.email_templates.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateTemplateModal from '@/components/project/CreateTemplateModal';
import DeleteModal from '@/components/shared-modals/DeleteModal';

export default {
  name: 'EmailTemplates',
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    projectId() {
      return this.$route.params.projectId;
    },
    templates() {
      const templates = this.projectData?.[this.projectId]?.templates || {};
      return Object.values(templates);
    },
  },
  methods: {
    ...mapActions(['fetchProjectTemplates', 'showToastMessage', 'deleteProjectTemplate']),
    onCreateClicked() {
      this.$showModal(CreateTemplateModal, { projectId: this.projectId });
    },
    onEditClicked(template) {
      this.$showModal(CreateTemplateModal, { projectId: this.projectId, templateId: template.template_id });
    },
    onDeleteClicked(emailTemplate) {
      this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.email_template.remove_template', { templateName: emailTemplate.name }),
        info: null,
        onConfirm: async () => {
          await this.onDeleteConfirmClicked(emailTemplate);
        },
      });
    },
    async onDeleteConfirmClicked(item) {
      const response = await this.deleteProjectTemplate({ project_id: this.projectId, template_id: item.template_id });
      if (response) {
        await this.fetchProjectTemplates({ projectId: this.projectId });
      }
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectTemplates({ projectId: this.$route.params.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
