<template>
  <div class="flex flex-row justify-start items-start mt-5">
    <div class="min-h-9">
      <Prompt :disabled="disabled" :title="prompt.title" :subtitle="prompt.subtitle" :horizontal="prompt.direction === 'horizontal'" :items="prompt.items" @item-click="$emit('item-click', $event)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConversationPrompt',
  props: {
    prompt: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
