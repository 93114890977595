<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
          <div v-for="user in users" :key="`${user.email}-${user.role}`">
            <ListCard class="flex-row mt-3">
              <div class="mr-4 truncate">{{ user.email }}</div>
              <Badge class="w-20" info :text="user.role"></Badge>
              <IconButton icon="edit" class="ml-auto text-primary" />
            </ListCard>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>
<script>
import firebase_authentication from '@/assets/png/firebase_authentication.png';
import { apiPutProjectAuthentication } from '@/helpers/api';
import { mapGetters } from 'vuex';
import delay from '@/helpers/delay';

export default {
  name: 'AuthenticationUsers',
  data() {
    return {
      fetching: false,
      activeProvider: null,
      providerConfig: {},
      emailPasswordEnabled: false,
      authProviders: [
        {
          name: 'Firebase Authentication',
          value: 'FIREBASE',
          icon: firebase_authentication,
        },
      ],
      users: [],
    };
  },
  computed: {
    ...mapGetters(['getProjectSecrets']),
    projectId() {
      return this.$route.params.projectId;
    },
    secrets() {
      return Object.keys(this.getProjectSecrets()).map((i) => ({ label: i, value: i }));
    },
  },
  methods: {
    onProviderClick(provider) {
      this.activeProvider = provider.value;
    },
    onSave() {
      apiPutProjectAuthentication({
        project_id: this.projectId,
        provider: this.activeProvider,
        config: this.providerConfig,
      });
    },
  },
  created() {},
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await delay(1500);
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
