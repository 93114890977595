import { createRouter, createWebHistory } from 'vue-router';
import SideLayout from '@/components/layouts/Side';
import Login from '@/components/views/Login';
import Settings from '@/components/views/user/Settings.vue';
import Security from '@/components/views/user/Security.vue';
import Subscription from '@/components/views/user/Subscription';
import AuthCallback from '@/components/views/AuthCallback';
import Onboarding from '@/components/views/Onboarding';
import Projects from '@/components/views/project/Projects';
import { FEATURES } from '@/constants';
import Help from '@/components/views/Help.vue';
import Dashboard from '@/components/views/Dashboard.vue';
import AdminDashboard from '@/components/views/admin/AdminDashboard.vue';
import ProjectFlow from '@/components/views/project/NoCodeFlow.vue';
import ProjectEndpoints from '@/components/views/project/Endpoints';
import ProjectVariables from '@/components/views/project/Variables';
import ProjectSecrets from '@/components/views/project/Secrets';
import ProjectIAM from '@/components/views/project/NoCodeIAM';
import ProjectTemplates from '@/components/views/project/Templates';
import ProjectSettingsWrapper from '@/components/views/project/SettingsWrapper';
import AuthenticationConfig from '@/components/views/project/AuthenticationConfig';
import AuthenticationTeamMembers from '@/components/views/project/AuthenticationTeamMembers';
import AuthenticationUsers from '@/components/views/project/AuthenticationUsers';
import ProjectDatabase from '@/components/views/project/Database';
import ProjectSettings from '@/components/views/project/Settings';
import ProjectPersonas from '@/components/views/project/Personas';
import ProjectDataTypes from '@/components/views/project/DataTypes';
import ProjectFlows from '@/components/views/project/Flows';
import ProjectModels from '@/components/views/project/Models';
import ProjectModel from '@/components/views/project/Model';
import ProjectModelSettings from '@/components/views/project/ModelSettings';
import ProjectModelSuggestions from '@/components/views/project/ModelSuggestions';
import ProjectModelFineTune from '@/components/views/project/ModelFineTune';
import ProjectModelBuild from '@/components/views/project/ModelBuild';
import ProjectPublishing from '@/components/views/project/Publish';
import Users from '@/components/views/admin/Users.vue';

let store;

const router = createRouter({
  history: createWebHistory(),
  base: '/',
  routes: [
    {
      path: '/',
      component: Dashboard,
      meta: { requiresAuth: true },
    },
    {
      path: '/help/:id',
      name: 'help',
      component: Help,
      meta: { requiresAuth: true },
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: { requiresAuth: true },
    },
    {
      path: '/user',
      component: SideLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'subscriptions',
          name: 'subscriptions',
          component: Subscription,
          meta: { requiresAuth: true },
        },
        {
          path: 'settings',
          name: 'settings',
          component: Settings,
          meta: { requiresAuth: true },
        },
        {
          path: 'security',
          name: 'security',
          component: Security,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: '/project',
      name: 'project',
      component: Projects,
      meta: { requiresAuth: true },
    },
    {
      path: '/project/:projectId',
      name: 'project-parent',
      component: SideLayout,
      meta: { requiresAuth: true, header: 'no_code' },
      children: [
        {
          path: '/project/:projectId/endpoints',
          name: 'project-endpoints',
          component: ProjectEndpoints,
        },
        {
          path: '/project/:projectId/variables',
          name: 'project-variables',
          component: ProjectVariables,
        },
        {
          path: '/project/:projectId/secrets',
          name: 'project-secrets',
          component: ProjectSecrets,
        },
        {
          path: '/project/:projectId/iam',
          name: 'project-iam',
          component: ProjectIAM,
        },
        {
          path: '/project/:projectId/templates',
          name: 'project-templates',
          component: ProjectTemplates,
        },
        {
          path: '/project/:projectId/settings',
          component: ProjectSettingsWrapper,
          children: [
            {
              path: 'settings',
              name: 'project-settings',
              component: ProjectSettings,
            },
            {
              path: 'authentication',
              name: 'project-authentication-config',
              component: AuthenticationConfig,
            },
            {
              path: 'authentication',
              name: 'project-authentication-users',
              component: AuthenticationUsers,
            },
            {
              path: 'team-members',
              name: 'project-authentication-team-members',
              component: AuthenticationTeamMembers,
            },
            {
              path: 'users',
              name: 'project-authentication-users',
              component: AuthenticationUsers,
            },
          ],
        },
        {
          path: '/project/:projectId/publishing',
          name: 'project-publishing',
          component: ProjectPublishing,
        },
        {
          path: '/project/:projectId/database',
          name: 'project-database',
          component: ProjectDatabase,
        },
        {
          path: '/project/:projectId/personas',
          name: 'project-personas',
          component: ProjectPersonas,
        },
        {
          path: '/project/:projectId/datatypes',
          name: 'project-datatypes',
          component: ProjectDataTypes,
        },
        {
          path: '/project/:projectId/flow',
          name: 'project-flows',
          component: ProjectFlows,
        },
        {
          path: '/project/:projectId/models/list',
          name: 'project-models',
          component: ProjectModels,
        },
        {
          path: '/project/:projectId/models',
          component: ProjectModel,
          children: [
            {
              path: ':modelId',
              name: 'project-model',
              component: ProjectModelSettings,
            },
            {
              path: ':modelId/suggestions',
              name: 'project-model-suggestions',
              component: ProjectModelSuggestions,
            },
            {
              path: ':modelId/fine-tune',
              name: 'project-model-fine-tune',
              component: ProjectModelFineTune,
            },
            {
              path: ':modelId/build',
              name: 'project-model-build',
              component: ProjectModelBuild,
            },
          ],
        },
      ],
    },
    {
      path: '/project/:projectId/flow/:flowId',
      name: 'project-flow',
      component: ProjectFlow,
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminDashboard,
      meta: { requiresAuth: true, feature: FEATURES.ADMIN_DASHBOARD },
    },
    {
      path: '/admin',
      component: SideLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'users',
          name: 'admin-users',
          component: Users,
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/passwordreset',
      name: 'password-reset',
      component: Login,
    },
    {
      path: '/register',
      name: 'register',
      component: Login,
    },
    {
      path: '/authcallback',
      name: 'authcallback',
      component: AuthCallback,
    },
    // pathMatch is the name of the param, e.g., going to /not/found yields
    // { params: { pathMatch: ['not', 'found'] }}
    // this is thanks to the last *, meaning repeated params and it is necessary if you
    // plan on directly navigating to the not-found route using its name
    { path: '/:pathMatch(.*)*', name: 'not-found', redirect: '/login' },
    // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
    { path: '/:pathMatch(.*)', name: 'bad-not-found', redirect: '/login' },
  ],
});

router.setStore = (vuexStore) => {
  store = vuexStore;
};

router.beforeEach(async (to, from, next) => {
  if (to.fullPath && to.query.redirect && to.path !== '/authcallback') {
    next(to.query.redirect);
    return;
  }
  if (store.state.bootstrapped && to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.user || !store.state.user.email) {
      next({ path: '/login', params: { nextUrl: to.fullPath } });
      return;
    }
    const { users } = to.meta;
    if (users && users.length && !users.includes(store.state.user.email)) {
      // email not authorised so redirect to home page
      next('/');
      return;
    }

    if (to.matched.some((record) => record.meta.feature && !store.state.features.includes(record.meta.feature))) {
      next({ path: '/' });
      return;
    }
  }

  next();
});

router.afterEach((to, from) => {
  if (from.name === 'dashboard' && to.name === 'conversation' && to.hash === '#dashboard') {
    to.meta.transition = 'slide-up';
  }
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
