export const shuffle = (collection) => {
  const pick = [...collection];
  const rand = () => Math.floor(Math.random() * pick.length);
  return collection.map(() => {
    const i = rand();
    const item = pick[i];
    pick.splice(i, 1);
    return item;
  });
};

export function sortByKey(arr, key = 'name') {
  return arr.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));
}
export function sort(arr) {
  return arr.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
}

export default {
  shuffle,
};
