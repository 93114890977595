<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2 h-14">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('projects.personas.title') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <template v-if="personas.length">
          <div v-for="(persona, index) in personas" :key="`${index}-${persona.id}`">
            <ListCard class="flex-row mt-3">
              <div class="mr-4 truncate">{{ persona.name }}</div>
              <IconButton icon="edit" class="ml-auto text-primary" @click="onEditClicked(persona)"></IconButton>
              <IconButton icon="delete" class="sm:ml-1 text-primary" @click="onDeleteClicked(persona)"></IconButton>
            </ListCard>
          </div>
        </template>
        <div v-else class="flex flex-col h-full flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.personas.have_no_personas') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.personas.create_first_persona') }}</div>
          <PillButton class="my-3" :text="$t('projects.personas.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="fetching">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>
<script>
import CreatePersonaModal from '@/components/project/CreatePersonaModal.vue';
import DeleteModal from '@/components/shared-modals/DeleteModal.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Personas',
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    personas() {
      return Object.values(this.projectData[this.projectId]?.personas || {});
    },
    projectId() {
      return this.$route.params.projectId;
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectPersonas', 'deleteProjectPersona']),
    onCreateClicked() {
      this.$showModal(CreatePersonaModal, { projectId: this.projectId });
    },
    onEditClicked(persona) {
      this.$showModal(CreatePersonaModal, { projectId: this.projectId, editData: persona });
    },
    onDeleteClicked(persona) {
      const modal = this.$showModal(DeleteModal, {
        subtitle: this.$t('projects.personas.remove_persona', { templateName: persona.name }),
        info: null,
        onConfirm: async () => {
          await this.onDeleteConfirmClicked(persona, modal);
        },
      });
    },
    async onDeleteConfirmClicked(item) {
      await this.deleteProjectPersona({ project_id: this.projectId, persona_id: item.persona_id });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.fetching = true;
        await this.fetchProjectPersonas({ projectId: this.$route.params.projectId });
        this.fetching = false;
      },
      immediate: true,
    },
  },
};
</script>
