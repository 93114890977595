<template>
  <div :id="step.id" class="bg-white shadow-lg-eq rounded-6 cursor-pointer select-none relative" @click="$emit('click', $event)">
    <div v-if="icon" class="absolute right-3 top-3"><Icon :name="icon" class="w-4 text-gray-600" /></div>
    <div class="border-b border-solid border-gray-200 px-3 py-2 text-13 text-gray-500" :class="{ 'font-500': selected }" @click="onSectionClicked()">
      <template v-if="step.name === 'Start'">
        <div v-if="globalIntent && globalIntentType === 'conversation'" class="flex items-center">
          <Icon name="flag" class="w-4 text-pink-500" /> <span class="ml-1 font-500">{{ $t('conversations.conversation_starts_here') }}</span>
        </div>
        <div v-if="globalIntent && globalIntentType === 'collection'" class="flex items-center">
          <Icon name="flag" class="w-4 text-pink-500" /> <span class="ml-1 font-500">{{ $t('conversations.conversation_starts_here') }}</span>
        </div>
        <div v-if="globalIntent && globalIntentType === 'project'" class="flex items-center">
          <Icon name="flag" class="w-4 text-pink-500" /> <span class="ml-1 font-500">{{ $t('flow.requests_starts_here') }}</span>
        </div>
      </template>
      <template v-else>{{ step.name }}</template>
    </div>
    <div class="px-3 py-1">
      <div v-if="globalIntent">
        <FlowStepListItem v-if="globalIntentType === 'project'" @click="onSectionClicked('request-parameters')">
          <div v-if="step.request_params && step.request_params.length" class="flex">
            <span class="mr-1">{{ $t('conversations.params') }}:</span>
            <span>{{ requestParams }} </span>
          </div>
          <div v-else>{{ $t('conversations.no_params') }}</div>
        </FlowStepListItem>
        <FlowStepListItem :icon="TRANSITION_ICONS.step" id="start-transition" icon-class="text-blue-500">
          {{ conversationTransition }}
        </FlowStepListItem>
      </div>
      <FlowStepList :steps="steps" :step="step" :list-items="listItems" />
    </div>
  </div>
</template>

<script>
import FlowStepListItem from '@/components/flow/FlowStepListItem';
import { TRANSITION_ICONS } from '@/helpers/flow';
import FlowStepList from '@/components/flow/FlowStepList';

export default {
  name: 'FlowStepItem',
  components: { FlowStepList, FlowStepListItem },
  props: {
    steps: {
      type: Array,
    },
    step: {
      type: Object,
    },
    selected: Boolean,
  },
  emits: ['click', 'section'],
  data() {
    return {
      opened: false,
      section: null,
      TRANSITION_ICONS,
    };
  },
  computed: {
    conversationTransition() {
      if (this.step.transition) {
        const step = this.steps.find((s) => s.id === this.step.transition);
        return step?.name;
      }
      return 'Select Step';
    },
    globalIntent() {
      return this.step.name === 'Start';
    },
    globalIntentType() {
      return this.step?.type;
    },
    icon() {
      return this.step.name === 'Start' ? 'lock' : null;
    },
    listItems() {
      const listItems = [];

      if (this.step?.evaluate) {
        this.step.evaluate.forEach((evaluateItem) => {
          listItems.push(evaluateItem);
        });
      }

      return listItems;
    },
    requestParams() {
      return this.step?.request_params?.map((p) => p.key)?.join(', ') || undefined;
    },
  },
  methods: {
    onSectionClicked(section) {
      this.section = section;
      this.$emit('section', section);
    },
  },
  watch: {
    selected(newValue) {
      if (!newValue) {
        this.section = null;
      }
    },
  },
};
</script>
