<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.flows') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <template v-if="flowList.length">
          <ListCard v-for="flow in flowList" :key="flow.flow_id" class="flex-row mt-3">
            <div class="text-14 font-500 truncate">{{ flow.name }}</div>
            <div class="flex items-center flex-1">
              <div class="flex flex-row justify-end items-center ml-auto">
                <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(flow)" />
                <IconButton class="text-primary" icon="delete" @click="onDeleteClicked(flow)" />
              </div>
            </div>
          </ListCard>
        </template>
        <div v-else class="flex flex-col h-full flex-grow items-center justify-center pb-50">
          <div class="my-2 text-22 text-gray-700">{{ $t('projects.flows.have_no_flows') }}</div>
          <div class="my-2 text-gray-600">{{ $t('projects.flows.create_first_flow') }}</div>
          <PillButton class="my-3" :text="$t('projects.flows.create_new')" primary @click="onCreateClicked" />
        </div>
      </div>
    </div>
    <Modal v-if="loadingIntentsModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import { sortByKey } from '@/helpers/array';
import CreateProjectFlow from '@/components/project/CreateProjectFlow.vue';

export default {
  name: 'Flows',
  data() {
    return {
      conversations: [],
      conversationDetails: {},
      loadingIntentsModal: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'projectData']),
    projectId() {
      return this.$route.params.projectId;
    },
    flows() {
      return this.projectData?.[this.projectId]?.flows || {};
    },
    flowList() {
      return sortByKey(Object.values(this.flows), 'name');
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectFlows', 'deleteProjectFlow']),
    onCreateClicked() {
      this.$showModal(CreateProjectFlow);
    },
    onEditClicked(flow) {
      this.$router.push({ name: 'project-flow', params: { projectId: this.projectId, flowId: flow.flow_id } });
    },
    onDeleteClicked(flow) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('projects.flows.confirm_remove_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.deleteProjectFlow({ project_id: this.projectId, flow_id: flow.flow_id });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.loadingIntentsModal = true;
        await this.fetchProjectFlows({ projectId: this.projectId });
        this.loadingIntentsModal = false;
      },
      immediate: true,
    },
  },
};
</script>
