import i18n from '@/plugins/i18n';
import uuidv4 from '@/helpers/uuid';

export const TRANSITION_ICONS = {
  repeat: 'repeat',
  step: 'transition_right',
  conversation: 'repeat',
  end: 'square',
};

export function getTransitionText(transitionPayload, steps, variableSelectorType) {
  if (!transitionPayload) {
    return '';
  }
  switch (transitionPayload.type) {
    case 'step': {
      const text = steps.find((s) => s.id === transitionPayload.value)?.name;
      if (text) {
        return text;
      }
      return i18n.global.t('conversations.invalid_step');
    }
    case 'repeat':
      return i18n.global.t('conversations.this_step');
    case 'conversation':
      return i18n.global.t('conversations.conversation_x', { conversation: transitionPayload.value ? transitionPayload.value : '' });
    case 'end':
      if (variableSelectorType === 'project') {
        return i18n.global.t('flow.end_request');
      }
      return i18n.global.t('conversations.end_conversation');
    default:
      return '';
  }
}

export function getTransitions(evaluates, transitions, stepId) {
  if (!evaluates) return;

  evaluates.forEach((evaluate) => {
    if (evaluate.type === 'transition') {
      transitions.push({ to: evaluate.payload.value, from: stepId, id: evaluate.payload.id, payload: { ...evaluate.payload } });
    } else if (evaluate.type === 'condition') {
      evaluate.payload.expressions.forEach((expression) => {
        getTransitions(expression.payload, transitions, stepId);
      });
      getTransitions(evaluate.payload.else, transitions, stepId);
    }
  });
}

export function getExpressionLabel(index, length) {
  if (index === 0) {
    return 'IF';
  }
  if (index === length - 1) {
    return 'ELSE';
  }
  return 'ELSE IF';
}

export function removeEvaluateItem(evaluates, id) {
  if (!id) {
    return;
  }

  const index = evaluates.findIndex((evaluate) => evaluate.id === id);
  if (index > -1) {
    evaluates.splice(index, 1);
  }

  evaluates.forEach((evaluate) => {
    if (evaluate.type === 'condition') {
      removeEvaluateItem(evaluate.payload.expressions, id);
      evaluate.payload.expressions.forEach((expression) => {
        removeEvaluateItem(expression.payload, id);
      });
      removeEvaluateItem(evaluate.payload.else, id);
    } else if (evaluate.type === 'parallel') {
      removeEvaluateItem(evaluate.payload, id);
    }
  });
}
// eslint-disable-next-line consistent-return
export function addEvaluateItem(evaluates, id, index, item, evaluateKey) {
  if (!id) {
    evaluates.splice(index, 0, item);
    return item;
  }

  const parent = evaluates.find((evaluate) => evaluate.id === id);
  if (parent) {
    if (Array.isArray(parent.payload)) {
      parent.payload.splice(index, 0, item);
    } else if (typeof parent === 'object' && evaluateKey in parent.payload) {
      parent.payload[evaluateKey].splice(index, 0, item);
    }
    return item;
  }

  evaluates.forEach((evaluate) => {
    if (evaluate.type === 'condition') {
      addEvaluateItem(evaluate.payload.expressions, id, index, item, evaluateKey);
      evaluate.payload.expressions.forEach((expression) => {
        addEvaluateItem(expression.payload, id, index, item, evaluateKey);
      });
      addEvaluateItem(evaluate.payload.else, id, index, item, evaluateKey);
    }
  });
  return item;
}

export function updateEvaluateItem(evaluates, evaluateItem) {
  const updatePayload = JSON.parse(JSON.stringify(evaluateItem));
  const index = evaluates.findIndex((evaluate) => evaluate.id === updatePayload.id);
  if (index > -1) {
    evaluates[index] = updatePayload;
    return;
  }

  evaluates.forEach((evaluate) => {
    if (evaluate.type === 'condition') {
      updateEvaluateItem(evaluate.payload.expressions, updatePayload);
      evaluate.payload.expressions.forEach((expression) => {
        updateEvaluateItem(expression.payload, updatePayload);
      });
      updateEvaluateItem(evaluate.payload.else, updatePayload);
    } else if (evaluate.type === 'parallel') {
      updateEvaluateItem(evaluate.payload, updatePayload);
    }
  });
}

export function removeItemsByType(evaluates, types) {
  evaluates = evaluates.filter((item) => !types.includes(item.type));

  evaluates.forEach((evaluate) => {
    if (evaluate.type === 'condition') {
      evaluate.payload.expressions = removeItemsByType(evaluate.payload.expressions, types);
      evaluate.payload.expressions.forEach((expression) => {
        expression.payload = removeItemsByType(expression.payload, types);
      });
    } else if (evaluate.type === 'parallel') {
      evaluate.payload = removeItemsByType(evaluate.payload, types);
    }
  });
  return evaluates;
}

export function generateStepName(count, steps) {
  const name = `Step ${count}`;
  if (steps.some((step) => step.name === name)) {
    return generateStepName(count + 1, steps);
  }
  return name;
}

export function addNewStep(steps, { column = 0, order = null } = {}) {
  const stepItem = {
    id: uuidv4(),
    name: generateStepName(1, steps),
    column,
    order,
    evaluate: [],
  };
  if (order === null) {
    const columnSteps = steps.filter((s) => s.column === column);
    stepItem.order = columnSteps.length;
  }
  steps.push(stepItem);
  return stepItem;
}
