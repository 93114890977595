<template>
  <div>
    <FormLabel :label="label" :error="!!error" />
    <Input
      :id="id ? id : null"
      :class="{ '!border-alert': !!error }"
      ref="input"
      class="w-full"
      :name="addNameAttr ? name : undefined"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :modelValue="modelValue"
      :label="label"
      :error="error"
      :maxlength="maxlength"
      @update:modelValue="updateValue"
      @blur="onBlur"
      @keyup="$emit('keyup', $event)"
    />
    <label v-if="error || $slots['error']" class="text-12 text-alert mt-3 mb-1">{{ error }} <slot name="error"></slot></label>
  </div>
</template>

<script>
import ValidationMixin from '../../mixins/ValidationMixin';
import FormLabel from './FormLabel.vue';

export default {
  name: 'FormInput',
  components: { FormLabel },
  mixins: [ValidationMixin],
  emits: ['update:modelValue', 'blur', 'keyup'],
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    readonly: Boolean,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: String,
    error: String,
    id: String,
    addNameAttr: {
      type: Boolean,
    },
    maxlength: {
      type: String,
    },
  },
  methods: {
    focus() {
      this.$refs.input.$el.focus();
    },
    updateValue(value) {
      this.eventbus.emit('input', value);
      this.$emit('update:modelValue', value);
    },
    onBlur($event) {
      this.eventbus.emit('blur', $event);
      this.$emit('blur', $event);
    },
  },
};
</script>
