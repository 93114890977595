<template>
  <div class="fixed top-0 mt-3 max-w-350 z-[10000] right-0 mr-5">
    <transition-group name="notification" tag="div">
      <div
        class="w-full flex transition-all justify-between shadow-md duration-300 border-0 border-l-4 border-solid text-14 rounded-4 bg-white relative overflow-hidden max-h-150"
        :class="[
          notification.type === 'default' ? 'border-indigo-500' : '',
          notification.type === 'success' ? 'border-green-500' : '',
          notification.type === 'error' ? 'border-red-500' : '',
          index + 1 !== notificationState.length ? 'mb-4' : '',
        ]"
        v-for="(notification, index) in notificationState"
        :key="notification.key + index"
      >
        <div class="text-left flex px-6 py-4 flex-col justify-center">
          <div v-if="notification.title" class="font-700">{{ notification.title }}</div>
          <div class="text-gray-700">{{ notification.message }}</div>
        </div>
        <div class="close transition-all duration-300 flex cursor-pointer text-22 text-gray-600 align-center max-w-50 py-4 px-4" @click="$emit('close', notification.key)">×</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    notificationState: Array,
  },
};
</script>

<style scoped>
.close:hover {
  background-color: #ddd;
  color: #222;
}

/* Vue Animations */
.notification-enter {
  opacity: 0;
  transform: translateX(384px);
}

.notification-leave-to {
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.notification-leave-active {
  position: absolute;
}
</style>
