<template>
  <div>
    <div v-for="(expression, index) in payloadData.expressions" :key="`expression-${index}`">
      <div class="flex">
        <span class="font-700 mr-1">{{ getExpressionLabel(index, payloadData.expressions.length + 1) }}</span> <ExpressionRenderer :expression="expression.value"></ExpressionRenderer>
      </div>
      <div v-for="(evaluateItem, evaluateIndex) in expression.payload" :key="evaluateItem.id">
        <EvaluateDropZone v-if="evaluateIndex === 0" :evaluate="evaluate" :index="evaluateIndex" :parent-id="expression.id" @evaluate-update="onDrop" />
        <EvaluateItem
          :item="evaluateItem"
          :evaluate="evaluate"
          :selectedEvaluateItemId="selectedEvaluateItemId"
          @update="onEvaluateItemUpdated($event, 'expressions', evaluateIndex, index)"
          @item-click.stop="onItemClick"
          @evaluate-update="$emit('evaluate-update', $event)"
        />
        <EvaluateDropZone v-if="evaluateIndex + 1 !== expression.payload.length" :evaluate="evaluate" :index="evaluateIndex + 1" :parent-id="expression.id" @evaluate-update="onDrop" />
      </div>
      <EvaluateDropZone :evaluate="evaluate" :parent-id="expression.id" :index="expression.payload.length" @evaluate-update="onDrop" />
    </div>
    <div>
      <span class="font-700">{{ getExpressionLabel(1, 2) }}</span>
    </div>
    <template v-if="payloadData.else.length">
      <div v-for="(evaluateItem, index) in payloadData.else" :key="`else-${index}`">
        <EvaluateDropZone v-if="index === 0" :evaluate="evaluate" :parent-id="item.id" :index="index" evaluate-key="else" @evaluate-update="onDrop" />
        <EvaluateItem
          :evaluate="evaluate"
          :selectedEvaluateItemId="selectedEvaluateItemId"
          :item="evaluateItem"
          @update="onEvaluateItemUpdated($event, 'else', index)"
          @item-click.stop="onItemClick"
          @evaluate-update="$emit('evaluate-update', $event)"
        />
        <EvaluateDropZone :evaluate="evaluate" :parent-id="item.id" :index="index + 1" evaluate-key="else" @evaluate-update="onDrop" />
      </div>
    </template>
    <template v-else>
      <EvaluateDropZone :evaluate="evaluate" :parent-id="item.id" :index="0" evaluate-key="else" @evaluate-update="onDrop" />
    </template>
  </div>
</template>

<script>
import { getExpressionLabel } from '@/helpers/flow';
import uuidv4 from '@/helpers/uuid';
import EvaluateDropZone from './EvaluateDropZone';

export default {
  name: 'EvaluateCondition',
  components: { EvaluateDropZone },
  props: {
    item: Object,
    evaluate: Array,
    selectedEvaluateItemId: String,
  },
  data() {
    return {
      payloadData: null,
    };
  },
  computed: {
    payload() {
      return this.item.payload;
    },
  },
  methods: {
    onDrop($event, evaluateItem, originalEvent) {
      this.$emit('evaluate-update', $event);
      this.onItemClick(originalEvent, evaluateItem);
    },
    onItemClick($event, evaluateItem) {
      this.$emit('item-click', $event, evaluateItem);
    },
    onEvaluateItemUpdated(evaluateItem, conditionType, evaluateIndex, index) {
      if (conditionType === 'expressions') {
        this.payloadData.expressions[index].payload[evaluateIndex] = evaluateItem;
      } else {
        this.payloadData.else[evaluateIndex] = evaluateItem;
      }
      this.$emit('update', this.payloadData);
    },
    updateEvaluation(evaluation, payloadIndex, conditionType) {
      if (conditionType === 'expressions') {
        const index = this.payloadData.expressions[payloadIndex].payload.findIndex((i) => i.payload.id === evaluation.payload.id);
        this.payloadData.expressions[payloadIndex].payload[index].payload = evaluation.payload;
      } else {
        this.payloadData.else[payloadIndex] = evaluation.payload;
      }
      this.$emit('update', this.payloadData);
    },
    deleteEvaluation(evaluation, payloadIndex, conditionType) {
      if (conditionType === 'expressions') {
        const index = this.payloadData.expressions[payloadIndex].payload.findIndex((i) => i.payload.id === evaluation.payload.id);
        delete this.payloadData.expressions[payloadIndex].payload[index];
      } else {
        delete this.payloadData.else[payloadIndex];
      }
      this.$emit('update', this.payloadData);
    },
    addEvaluation(evaluation, index, conditionType) {
      evaluation.id = uuidv4();
      if (conditionType === 'expressions') {
        this.payloadData.expressions[index].payload.push(evaluation);
      } else {
        this.payloadData.else.push(evaluation);
      }
      this.$emit('update', this.payloadData);
    },
    getExpressionLabel,
  },
  watch: {
    payload: {
      handler() {
        this.payloadData = JSON.parse(JSON.stringify(this.payload));
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
