<template>
  <Modal
    fullbelowsm
    :title="$t('settings.title')"
    close-button
    container-class="w-full sm:min-w-500 max-w-900"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    @primary="onConfirm"
    @secondary="onCancel"
    @close="onCancel"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }">
      <div class="px-10 py-5">
        <div class="mb-2">
          <div class="text-15 font-600 mb-1">{{ $t('projects.endpoints.endpoint') }}</div>
          <FormInput :readonly="loading" v-model="name" name="name" :error="errors.name" :rules="['required']" :label="$t('name')"></FormInput>
        </div>
        <div class="py-2">
          <div class="text-15 font-600 mb-1">{{ $t('conversations.transition') }}</div>
          <FormSelect
            :label="$t('conversations.switch_to_step')"
            class="mt-2"
            v-model="transitionValue"
            :options="steps"
            appendToBody
            :searchable="false"
            :clearable="false"
            :reduce="(option) => option.value"
          ></FormSelect>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FlowConfigModal',
  data() {
    return {
      transitionValue: null,
      name: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    steps() {
      return this.selectedIntentDefinition.steps.map((s) => ({
        value: s.id,
        label: s.name,
      }));
    },
  },
  created() {
    this.transitionValue = this.selectedIntentDefinition?.transition || '';
    this.name = this.selectedIntentDefinition.name;
  },
  methods: {
    async onConfirm() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid) return;
      this.$emit('definitionSave', { ...this.selectedIntentDefinition, name: this.name, transition: this.transitionValue });
      this.$emit('close');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
