<template>
  <div class="pl-4 pr-4 pt-4 mb-5 md:pt-0 md:mb-10 md:pl-0 md:pr-10">
    <div class="w-full">
      <div class="w-full flex justify-between">
        <div class="font-500 text-18">
          <span>{{ $t('subscription.plan_payments') }}</span>
        </div>
        <Quota class="mr-3" />
      </div>

      <div class="mt-4 mb-10">{{ $t('subscription.subscription_description') }}</div>
      <div class="flex flex-wrap" style="gap: 10px">
        <div class="m-1 shadow-card p-8 rounded-10 flex justify-between items-center flex-grow-[999] max-xs:flex-col max-sm:gap-2">
          <div>
            <div class="font-600 text-20">{{ planName }}</div>
            <div v-if="getSubscriptionWillBeCancelled">
              <div class="font-500 text-red-500">{{ $t('subscription.scheduled_cancellation') }}</div>
              <div>{{ $t('subscription.subscription_will_be_cancelled', { date: cancelScheduledAt }) }}</div>
            </div>
            <div v-else-if="getSubscriptionWillChange">
              <div class="font-500 text-red-500">{{ $t('subscription.scheduled_change') }}</div>
              <div>{{ $t('subscription.subscription_will_be_changed', { date: changeScheduledAt }) }}</div>
              <div>Sonraki Plan: {{ scheduledPlan }} ({{ periodText === 'month' ? $t('subscription.billed_monthly') : $t('subscription.billed_yearly') }})</div>
            </div>
            <div v-else>
              <div v-if="planName !== 'Free'" class="my-1 text-gray-700 capitalize">{{ periodText === 'month' ? $t('subscription.billed_monthly') : $t('subscription.billed_yearly') }}</div>
              <div v-if="planName !== 'Free'" class="my-1 text-gray-700 text-12">{{ $t('subscription.your_next_bill_on', { price, nextBillDate }) }}</div>
            </div>
          </div>
          <div v-if="!getSubscriptionWillBeCancelled && !getSubscriptionCancelled">
            <PillButton v-if="planName === 'Free'" primary :text="$t('subscription.upgrade')" @click="onUpgradeClick" />
            <PillButton v-else-if="scheduledPlan" outlined :text="$t('subscription.cancel_scheduled')" @click="onCancelScheduledClick" />
            <PillButton v-else primary :text="$t('subscription.change_plan')" @click="onChangePlanClick" />
          </div>
          <div v-if="getSubscriptionWillBeCancelled">
            <PillButton primary :text="$t('subscription.resume_subscription')" @click="onResumeClick" />
          </div>
        </div>
        <div v-if="planName !== 'Free'" class="m-1 shadow-card p-8 rounded-10 flex justify-between items-center w-full max-md:mt-3 flex-grow-[1] max-md:max-w-full" style="flex-basis: 300px">
          <div>
            <div class="font-600 text-20 flex items-center">{{ $t('settings.address.billing_address') }} <Icon name="edit" class="w-4 h-4 ml-3 cursor-pointer" @click="onEditAddressClick" /></div>
            <div v-if="getDefaultAddress" class="my-1 text-gray-700 text-12">
              {{ getDefaultAddress.first_line }} {{ getDefaultAddress.second_line }} {{ getDefaultAddress.region }} {{ getDefaultAddress.postal_code }} {{ getDefaultAddress.country_code }}
            </div>
          </div>
        </div>

        <div v-if="planName !== 'Free' && !getSubscriptionWillBeCancelled && !getSubscriptionCancelled" class="text-right w-full">
          <span class="hover:underline text-12 text-gray-400 cursor-pointer my-4 mr-5" @click="openCancelSubscriptionModal">{{ $t('subscription.cancel_your_subscription') }}</span>
        </div>
      </div>
    </div>

    <div class="w-full mt-10">
      <div class="font-500 text-18">{{ $t('subscription.transaction_history') }}</div>

      <div v-if="paymentsLoading" class="pt-5 flex-grow flex items-center justify-center">
        <Spinner class="w-8" />
      </div>
      <template v-else-if="payments && payments.length">
        <div class="mx-1 my-4 px-3 grid gap-x-1 grid-cols-12 text-12 text-gray-600">
          <div class="flex items-center col-span-3 font-700">{{ $t('invoice') }}</div>
          <div class="flex items-center col-span-4 font-700">{{ $t('date') }}</div>
          <div class="flex items-center col-span-2 font-700">{{ $t('payment.amount') }}</div>
        </div>
        <ListCard class="mx-1 my-2 py-1 grid gap-x-1 grid-cols-12 text-12" v-for="payment in payments" :key="payment.id" height-class="max-sm:h-15">
          <div class="max-md:col-span-4 col-span-3 font-700">
            <span v-if="payment.invoice_number" class="font-700 text-secondary cursor-pointer" @click="getInvoice(payment.id)">{{ payment.invoice_number }}</span>
            <span v-else>{{ $t('n_a') }}</span>
          </div>
          <div class="col-span-4">{{ dayjs(payment.billed_at).format('MMM DD, YYYY') }}</div>
          <div class="max-md:col-span-4 col-span-2 flex justify-center items-center flex-wrap">
            <div class="w-full sm:w-1/2 text-center">{{ getAmount(payment) }}</div>
            <div class="w-full sm:w-1/2 text-center"><Badge :text="$t('payment.paid')" success /></div>
          </div>
        </ListCard>
      </template>
      <template v-else>
        <div class="text-center text-14 mt-6 mb-2">{{ $t('subscription.no_payment_title') }}</div>
        <div class="text-center text-13">{{ $t('subscription.no_payment_message_1') }}</div>
      </template>
    </div>

    <Modal v-if="showCancelSubscribeModal" :title="$t('subscription.cancel_subscription')" close-button @close="showCancelSubscribeModal = false">
      <div class="max-w-700 py-3 text-center">
        <div class="text-15 px-10">{{ $t('subscription.cancel_modal.message_1') }}</div>
        <div class="text-15 px-10">{{ $t('subscription.cancel_modal.message_2') }}</div>
        <div class="text-13 text-left mt-3 mb-1 text-gray-600 px-10">{{ $t('subscription.cancel_modal.message') }}</div>
        <div class="text-15 text-left px-10">
          <Textarea v-model="cancelFeedback" :disabled="subscriptionProcessing" class="w-full min-h-150" :placeholder="$t('subscription.cancel_modal.message_placeholder')"></Textarea>
        </div>
        <div class="px-10">
          <Checkbox v-model="cancelAllowContact" :disabled="subscriptionProcessing" class="mt-3" :label="$t('subscription.cancel_modal.contact_agreement')" />
        </div>

        <div v-if="subscriptionProcessing" class="w-full mt-5 mb-2 flex flex-col items-center justify-center px-10">
          <Spinner class="w-6" />
          <div class="text-13 pt-2">{{ $t('subscription.subscription_processing') }}</div>
        </div>
        <div v-else class="px-7">
          <PillButton error :text="$t('subscription.cancel_subscription')" primary class="mt-6 mb-6" @click="onCancelSubscriptionClick" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions, mapState, mapGetters } from 'vuex';
import { CURRENCY_SYMBOLS, SUBSCRIPTION_STATES } from '@/constants';
import delay from '@/helpers/delay';
import Subscribe from '@/components/subscription/Subscribe';
import { padTrailingZeros, getAmount, convertPaddlePrice } from '@/helpers/pricing';
import PaddleController from '@/helpers/paddle';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import { apiGetInvoice } from '@/helpers/api';
import EditAddressModal from '@/components/subscription/EditAddressModal.vue';

export default {
  name: 'Subscription',
  data() {
    return {
      showCancelSubscribeModal: false,
      showContinueSubscribeModal: false,
      showPauseSubscribeModal: false,
      subscriptionProcessing: false,
      cancelFeedback: '',
      cancelAllowContact: false,
      dataSourceCollapse: {
        XERO: true,
        GOOGLE_ANALYTICS: true,
      },
      CURRENCY_SYMBOLS,
      SUBSCRIPTION_STATES,
      paymentsLoading: false,
    };
  },
  computed: {
    ...mapState(['data_sources', 'user', 'subscription', 'payments', 'quota', 'addresses']),
    ...mapGetters(['getSubscriptionWillBeCancelled', 'getSubscriptionCancelled', 'getSubscriptionWillChange', 'getDefaultAddress']),
    quotaText() {
      return this.quota;
    },
    periodText() {
      return this.getPeriodText(this.subscription.plan);
    },
    cancelScheduledAt() {
      if (this.subscription?.scheduled_change?.effective_at) {
        return dayjs(this.subscription.scheduled_change.effective_at).format('MMM DD, YYYY');
      }
      return '';
    },
    changeScheduledAt() {
      if (this.subscription?.scheduled_change?.effective_at) {
        return dayjs(this.subscription.scheduled_change.effective_at).format('MMM DD, YYYY');
      }
      return '';
    },
    price() {
      const { product_unit_price_amount, currency_code } = this.subscription;
      return product_unit_price_amount ? convertPaddlePrice(product_unit_price_amount, currency_code) : '';
    },
    nextBillDate() {
      if (this.subscription.next_billed_at) {
        return dayjs(this.subscription.next_billed_at).format('MMM DD, YYYY');
      }
      return '';
    },
    planName() {
      return this.getPlanName(this.subscription?.plan);
    },
    dataSourcesList() {
      return [];
    },
    subscriptionStatus() {
      return this.subscription?.status;
    },
    getLastPayment() {
      const totals = this.payments?.[0]?.details?.totals || {};
      if (this.payments?.[0]?.details?.totals) {
        return totals?.total ? convertPaddlePrice(totals?.total, totals?.currency_code) : null;
      }
      return '';
    },
    getLastPaymentMethodDetails() {
      return this.payments?.[0].payments?.[0]?.method_details;
    },
    scheduledPlan() {
      if (this.subscription?.scheduled_change?.price_id) {
        return this.getPlanName(this.subscription.scheduled_change.price_id);
      }
      return null;
    },
  },
  created() {
    this.paymentsLoading = true;
    this.fetchPayments().then(() => {
      this.paymentsLoading = false;
    });

    if (!this.addresses.length) {
      this.fetchAddresses();
    }
  },
  methods: {
    ...mapActions(['showToastMessage', 'sendFeedback', 'fetchPayments', 'cancelSubscription', 'getTenant', 'resumeSubscription', 'fetchAddresses']),
    dayjs,
    padTrailingZeros,
    onEditAddressClick() {
      this.$showModal(EditAddressModal);
    },
    getPlanName(id) {
      if (id === PaddleController.productId.PREMIUM_MONTHLY || id === PaddleController.productId.PREMIUM_ANNUAL) {
        return 'Premium';
      }
      if (id === PaddleController.productId.UNLIMITED_MONTHLY || id === PaddleController.productId.UNLIMITED_ANNUAL) {
        return 'Unlimited';
      }
      return 'Free';
    },
    getPeriodText(id) {
      if (id === PaddleController.productId.PREMIUM_MONTHLY || id === PaddleController.productId.UNLIMITED_MONTHLY) {
        return 'month';
      }
      if (id === PaddleController.productId.PREMIUM_ANNUAL || id === PaddleController.productId.UNLIMITED_ANNUAL) {
        return 'year';
      }
      return '';
    },
    getAmount(payment) {
      if (payment.origin === 'subscription_payment_method_change') {
        return 'N/A';
      }
      return getAmount(payment);
    },
    getInvoice(transaction_id) {
      apiGetInvoice({ transaction_id }).then((res) => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = res.data.url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
        }, 100);
      });
    },
    onUpgradeClick() {
      this.$showModal(Subscribe, { lastPaymentType: this.getLastPaymentType });
    },
    onChangePlanClick() {
      this.$showModal(Subscribe, { planId: this.subscription.plan, lastPaymentType: this.getLastPaymentType });
    },
    onCancelScheduledClick() {
      this.$showModal(DeleteModal, {
        title: this.$t('subscription.confirm_cancel_scheduled_title'),
        subtitle: this.$t('subscription.confirm_cancel_scheduled_message'),
        info: null,
        onConfirm: () => this.onCancelScheduledConfirm(),
      });
    },
    async onCancelScheduledConfirm() {
      this.resumeSubscriptionProcessing = true;
      const result = await this.resumeSubscription();

      if (result) {
        this.checkScheduledCancelledStatus();
        this.showToastMessage({ message: this.$t('subscription.resumed_successfully'), type: 'success' });
      }
      this.resumeSubscriptionProcessing = false;
    },
    async checkScheduledCancelledStatus() {
      if (this.subscription?.scheduled_change) {
        await delay();
        await this.getTenant();
        await this.checkScheduledCancelledStatus();
      }
      return true;
    },
    onResumeClick() {
      this.$showModal(DeleteModal, {
        title: this.$t('subscription.resume_subscription'),
        subtitle: this.$t('subscription.confirm_resume_message'),
        info: null,
        onConfirm: () => this.onResumeConfirm(),
      });
    },
    async onResumeConfirm() {
      const result = await this.resumeSubscription();
      if (result) {
        await this.checkTenantSubscriptionResumeStatus();
        this.showToastMessage({ message: this.$t('subscription.resumed_successfully'), type: 'success' });
      }
    },
    isSubscriptionExpired(dataSource) {
      const cancellation_effective_date = dataSource?.subscription?.cancellation_effective_date;
      const endDate = dayjs(cancellation_effective_date);
      return endDate.isBefore(dayjs(), 'day');
    },
    async openCancelSubscriptionModal() {
      this.showCancelSubscribeModal = true;
      this.cancelFeedback = '';
      this.cancelAllowContact = false;
    },
    async checkTenantSubscriptionCancelStatus() {
      if (!this.getSubscriptionWillBeCancelled) {
        await delay();
        await this.getTenant();
        await this.checkTenantSubscriptionCancelStatus();
      }
      return true;
    },
    async checkTenantSubscriptionResumeStatus() {
      if (this.getSubscriptionWillBeCancelled) {
        await delay();
        await this.getTenant();
        await this.checkTenantSubscriptionResumeStatus();
      }
      return true;
    },
    async onCancelSubscriptionClick() {
      this.subscriptionProcessing = true;
      const result = await this.cancelSubscription();

      if (result) {
        await this.checkTenantSubscriptionCancelStatus();

        this.showToastMessage({ message: this.$t('subscription.cancelled_successfully'), type: 'success' });
        if (this.cancelFeedback || this.cancelAllowContact) {
          await this.sendFeedback({ category: 'Subscription Cancellation', message: this.cancelFeedback, data: { allow_contact: this.cancelAllowContact }, hideNotification: true });
        }
      }
      this.subscriptionProcessing = false;
      this.showCancelSubscribeModal = false;
    },
  },
};
</script>
