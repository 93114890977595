<template>
  <div class="h-full">
    <div class="h-full flex flex-col">
      <div class="flex w-full pb-6 items-center flex-shrink-0 sm:pr-10 pl-2">
        <div class="flex-grow flex items-center">
          <div class="text-13 text-indigo-800 font-700">{{ $t('navigation.data_types') }}</div>
        </div>
        <div class="ml-auto flex flex-row justify-center">
          <PillButton icon="cable" :text="$t('data_types.relations')" outlined class="mr-3" @click="onRelationClicked" />
          <PillButton icon="plus" :text="$t('add_new')" primary @click="onCreateClicked" />
        </div>
      </div>
      <div class="w-full pr-3 flex-grow overflow-auto sm:pr-10 pl-2 pb-2">
        <ListCard v-for="(dataType, dataTypeName) in dataTypes" :key="dataTypeName" class="flex-row mt-3" :class="{ hidden: ['ChartLibrary', 'SystemEvent'].includes(dataTypeName) }">
          <div class="text-14 font-500 truncate">{{ dataTypeName }}</div>
          <div class="flex items-center flex-1">
            <span v-if="dataType.entityType === 'system'" class="ml-2 bg-indigo-200 rounded-6 px-1 text-12">system</span>
            <span v-if="dataType.entityType === 'user'" class="ml-2 bg-green-200 rounded-6 px-1 text-12">user</span>
            <div v-if="dataType.entityType !== 'system'" class="flex flex-row justify-end items-center ml-auto">
              <IconButton icon="edit" class="mr-1 text-primary" @click="onEditClicked(dataTypeName)" />
              <IconButton icon="delete" class="text-primary" @click="onDeleteClicked(dataTypeName)" />
            </div>
          </div>
        </ListCard>
      </div>
    </div>
    <Modal v-if="loadingIntentsModal">
      <div class="flex flex-col font-700 items-center justify-center px-20 my-10">
        <div>{{ $t('loading_data') }}</div>
        <Icon name="loading_dots" class="w-14 mt-3 text-primary" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CreateDatatypeRelation from '@/components/project/CreateDatatypeRelation.vue';
import DeleteModal from '@/components/shared-modals/DeleteModal';
import CreateProjectDataType from '@/components/project/CreateProjectDataType.vue';

export default {
  name: 'DataTypes',
  data() {
    return {
      dataTypeDetails: {},
      loadingIntentsModal: false,
    };
  },
  computed: {
    ...mapState(['user', 'designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'availableDataSources', 'projectData']),
    ...mapGetters(['getProjectDataTypes']),
    projectId() {
      return this.$route.params.projectId;
    },
    dataTypes() {
      return this.getProjectDataTypes();
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'fetchProjectDataTypes', 'deleteProjectDatatype']),
    onRelationClicked() {
      this.$showModal(CreateDatatypeRelation, {
        intentDetails: this.dataTypeDetails,
      });
    },
    onCreateClicked() {
      this.$showModal(CreateProjectDataType);
    },
    onEditClicked(dataTypeName) {
      this.$showModal(CreateProjectDataType, { dataType: dataTypeName });
    },
    onDeleteClicked(dataTypeName) {
      this.$showModal(DeleteModal, {
        title: this.$t('confirm'),
        subtitle: this.$t('projects.confirm_remove_data_type_message'),
        info: this.$t('all_data_will_be_removed'),
        onConfirm: async () => {
          await this.deleteProjectDatatype({ project_id: this.projectId, name: dataTypeName });
        },
      });
    },
  },
  watch: {
    projectId: {
      async handler() {
        this.loadingIntentsModal = true;
        await this.fetchProjectDataTypes({ projectId: this.projectId });
        this.loadingIntentsModal = false;
      },
      immediate: true,
    },
  },
};
</script>
