<template>
  <Swiper :class="{ invisible: showInsightsPanel }" :loop="supportedChartTypes.length > 1" class="w-full" ref="swiper" @slideChange="$emit('slideChange', $event)">
    <SwiperSlide v-for="(displayType, index) in supportedChartTypes" :key="index">
      <div class="card-container flex justify-center flex-row-reverse w-full select-none h-full">
        <div
          v-if="result.text && showCardText && displayType !== 'text'"
          :class="{ 'w-full': !displayType, 'w-1/2': displayType }"
          class="max-md:hidden flex flex-col pl-2 overflow-auto my-2 mb-4 pt-4"
          style="justify-content: safe center"
        >
          <div v-html="result.text" class="px-4 text-left"></div>
        </div>
        <div
          v-show="displayType"
          class="text-left flex items-center w-full h-full relative"
          :class="{
            'md:!w-1/2': !!result.text && showCardText && displayType !== 'text',
            'md:!w-full': !result.text || !showCardText || displayType === 'text',
            'mt-5': displayType === 'none',
            'p-2': displayType === 'table' || displayType === 'text',
          }"
        >
          <ChartSelector v-if="displayChart(displayType)" ref="chart-selector" :report="result" :height-class="exportMode ? 'h-full' : 'h-250'" :selected-chart-type="displayType" />
          <div v-if="displayTable(displayType)" class="relative flex w-full h-full" :style="{ minHeight: minH }">
            <div class="flex items-center w-full h-full absolute p-2">
              <DataTable :table="result.table" :dense="true" :show-full="exportMode" class="w-full max-h-full"></DataTable>
            </div>
          </div>
          <div v-if="displayText(displayType)" class="mb-6 w-full h-full flex items-center text-left overflow-auto py-4 px-2">
            <div class="my-auto" v-html="result.text"></div>
          </div>
          <img v-if="displayImage(displayType)" :src="result.image.url" class="max-w-10/12 mx-auto my-2" />
        </div>
        <div v-if="result.date" class="absolute right-2 -bottom-1 text-10 flex">{{ result.date }} <img :src="dataSourceLogo" class="ml-3 w-3 h-3" /></div>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script>
import { mapState } from 'vuex';
import ChartSelector from '@/components/charts/ChartSelector';
import DataTable from '@/components/conversation/DataTable';

export default {
  name: 'AnalysisCardSwiper',
  components: { ChartSelector, DataTable },
  emits: ['slideChange'],
  props: {
    result: Object,
    showExportButton: { type: Boolean, default: true },
    exportMode: Boolean,
    exportChartType: String,
    datasourceType: String,
    selectedChartType: String,
    showCardText: Boolean,
  },
  data() {
    return {
      showInsightsPanel: false,
      text: '',
    };
  },
  created() {
    const index = this.supportedChartTypes.indexOf(this.selectedChartType);
    this.$nextTick().then(() => {
      if (index > -1 && this.$refs.swiper) {
        this.$refs.swiper.slideTo(index, 0);
      }
    });
  },
  computed: {
    ...mapState(['availableDataSources']),
    minH() {
      return this.result.table.length <= 5 ? `${(this.result.table.length + 4) * 25}px` : `220px`;
    },
    dataSourceLogo() {
      return this.availableDataSources?.[this.datasourceType]?.logo || '';
    },
    supportedChartTypes() {
      const types = this.result?.chart?.map((c) => c.chart.type.toLowerCase()) || [];
      if (this.result?.image?.url) {
        types.push('image');
      }
      if (this.result.table) {
        types.push('table');
      }
      if (window.innerWidth < 996 && this.result.text) {
        types.push('text');
      }
      return types;
    },
  },
  methods: {
    displayTable(selectedChartType) {
      return this.result.table && selectedChartType === 'table';
    },
    displayImage(selectedChartType) {
      return this.result?.image?.url && selectedChartType === 'image';
    },
    displayText(selectedChartType) {
      return this.result?.text && selectedChartType === 'text';
    },
    displayChart(selectedChartType) {
      if (selectedChartType === 'none' || selectedChartType === 'table' || selectedChartType === 'image' || selectedChartType === 'text') {
        return false;
      }
      return this.result?.chart?.length;
    },
  },
};
</script>
