<template>
  <DefaultLayout showTopBar showAccounts v-if="bootstrapped">
    <div class="relative h-full flex flex-col overflow-hidden">
      <div v-if="getSubscriptionIsExpired">
        <Alert class="mr-4">Your subscription for one or more data sources has expired, please upgrade to continue viewing these.</Alert>
      </div>
      <div class="flex-grow overflow-auto w-full flex flex-col px-1 sm:px-2 pb-1 pt-11 lg:pt-0" :style="{ 'box-shadow': showShadowBorder ? '0px 5px 20px rgba(35, 17, 131, 0.02)' : '' }">
        <div class="flex w-full max-w-[1800px] mx-auto max-md:flex-wrap xs:px-2 lg:px-5 xl:px-10 transition-all ease-in duration-100">
          <div class="w-full md:w-7/12 lg:w-7/12">
            <div class="flex h-10 text-24 items-end font-500 mt-5 pl-6 text-semi-dark-text">{{ $t('dashboard.hello', { name }) }}</div>
            <div>
              <div class="w-full h-25 mt-3 border-stroke border-solid border rounded-20 p-4 flex">
                <div class="flex pl-5 justify-center flex-col w-1/3">
                  <span class="font-600 text-[30px]">{{ 0 }}</span> <span class="font-500">{{ $t('dashboard.collections') }}</span>
                </div>
                <div class="flex pl-5 justify-center flex-col w-1/3">
                  <span class="font-600 text-[30px]">{{ 0 }}</span> <span class="font-500">{{ $t('dashboard.total_cards') }}</span>
                </div>
                <div class="flex pl-5 justify-center flex-col w-1/3">
                  <span class="font-600 text-[30px]">{{ 0 }}</span> <span class="font-500">{{ $t('dashboard.data_sources') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-5/12 lg:w-5/12 pl-5 pt-[46px]">
            <div class="border-stroke border-solid border rounded-20 mt-6 px-2">
              <div class="flex h-10 text-18 items-end font-500 pl-4 mt-[7px] text-dark-text">{{ $t('dashboard.alerts_events') }}</div>
              <div class="p-4">{{ $t('dashboard.no_notifications') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

/* eslint-disable no-template-curly-in-string */

export default {
  name: 'Dashboard',
  data() {
    return {
      collectionQuestionResults: {},
      collectionQuestionIndex: 0,
      showShadowBorder: false,
    };
  },
  computed: {
    ...mapState(['user', 'bootstrapped']),
    ...mapGetters(['getSubscriptionIsExpired']),
    name() {
      return this.user?.name?.split(' ')?.[0] || '';
    },
  },
};
</script>
