<template>
  <div class="shadow-navigation w-full rounded-t-10 flex flex-row items-start relative bg-primary">
    <div class="w-14 h-14 ml-2 flex-shrink-0 flex justify-center items-center" @click="$router.push('/')"><img :src="navigationLogo" class="h-6" /></div>

    <BottomNavigationItem v-for="(item, index) in navigationItems" :key="index" class="sm:mx-2" :icon="item.icon" :selected="isNavItemSelected(item)" @click="selectNavItem(item)" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BottomNavigationItem from './BottomNavigationItem';
import { FEATURES } from '@/constants';
import DeleteModal from '../shared-modals/DeleteModal.vue';

export default {
  name: 'BottomNavigation',
  model: {
    prop: 'value',
    event: 'input',
  },
  components: { BottomNavigationItem },
  props: {
    value: Boolean,
  },
  data() {
    return {
      navItems: [
        { label: this.$t('navigation.conversations'), to: '/', icon: 'conversations', restrictTo: FEATURES.CONVERSATIONS },
        { label: this.$t('navigation.collections'), to: '/collections', icon: 'collections', restrictTo: FEATURES.COLLECTIONS },
        { label: this.$t('navigation.projects'), to: '/project', icon: 'code_off', restrictTo: FEATURES.PROJECTS },
        { label: this.$t('navigation.settings'), to: '/user/settings', icon: 'settings', restrictTo: FEATURES.SETTINGS },
        { label: this.$t('navigation.logout'), to: '/logout', icon: 'logout' },
      ],
    };
  },
  computed: {
    ...mapState(['user', 'features']),
    navigationLogo() {
      return process.env.VUE_APP_NAVIGATION_LOGO;
    },
    navigationItems() {
      return this.navItems.filter((item) => {
        if (item.restrictTo) {
          return this.features.includes(item.restrictTo);
        }
        return true;
      });
    },
  },
  methods: {
    ...mapActions(['logout']),
    selectNavItem(item) {
      if (item.to === '/logout') {
        this.$showModal(DeleteModal, {
          title: this.$t('logout'),
          subtitle: this.$t('confirm_logout_message'),
          confirm: this.$t('logout'),
          onConfirm: () => {
            this.logout({ clearIdb: true });
          },
        });
      } else {
        this.$router.push(item.to);
      }
    },
    isNavItemSelected(item) {
      if (item.to.startsWith('/admin')) {
        return this.$route.path.startsWith('/admin') || this.$route.path.startsWith('/iam');
      }
      if (item.to.startsWith('/user')) {
        return this.$route.path.startsWith('/user');
      }
      if (item.to.startsWith('/project')) {
        return this.$route.path.startsWith('/project');
      }
      if (item.to.startsWith('/collection')) {
        return this.$route.path.startsWith('/collection');
      }
      return this.$route.path === item.to;
    },
  },
};
</script>
