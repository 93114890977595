<template>
  <Modal
    sheetbelowsm
    :title="$t('projects.iam.invite_member')"
    :primary-button="$t('save')"
    :secondary-button="$t('cancel')"
    :primary-button-disabled="loading"
    :primary-button-loading="loading"
    close-button
    :loading="fetching"
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @close="onCancelClick"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }">
      <div class="w-full sm:w-700 px-10">
        <div class="w-full flex flex-col justify-start py-2">
          <FormInput :readonly="loading" v-model="email" name="email" :error="errors.email" :rules="['required', 'email']" :label="$t('email')"></FormInput>
        </div>
        <div class="w-full flex flex-col justify-start py-2">
          <FormSelect
            append-to-body
            :readonly="loading"
            v-model="role"
            :options="getRoles"
            :reduce="(opt) => opt.value"
            name="role"
            :error="errors.role"
            :rules="['required']"
            :label="$t('role')"
          ></FormSelect>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import { TEAM_MEMBER_ROLES } from '@/constants';

export default {
  name: 'TeamInviteModal',
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      fetching: false,
      email: null,
      role: null,
    };
  },
  computed: {
    getRoles() {
      const roles = Object.values(TEAM_MEMBER_ROLES);
      return roles.map((role) => ({ label: this.$t(`projects.roles.${role}`), value: role }));
    },
  },
  methods: {
    ...mapActions(['inviteTeamMember', 'fetchTeamMembers']),
    async onSaveClick() {
      const valid = await this.$refs.validationObserver.validate();
      if (!valid.valid) return;

      this.loading = true;
      const response = await this.inviteTeamMember({ project_id: this.projectId, email: this.email.trim(), role: this.role });
      if (response) {
        await this.fetchTeamMembers({ project_id: this.projectId });
        this.$emit('close');
      }
      this.loading = false;
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>
