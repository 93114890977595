<template>
  <div ref="collapse-card" class="bg-gray-100 w-full flex justify-center rounded-10 overflow-hidden">
    <div class="bg-white w-full mx-auto">
      <div class="relative h-full flex flex-col">
        <div ref="header" class="flex-shrink-0 flex justify-between items-center rounded-t-10 cursor-pointer pl-4 pr-2" :class="{ 'min-h-13': !dense, 'min-h-10': dense }" @click="toggle">
          <template v-if="title">
            <span class="text-16">{{ title }}</span>
            <div class="flex-grow"></div>
            <div>
              <slot name="header-button" />
            </div>
          </template>
          <template v-else>
            <slot name="header" />
          </template>
          <Icon v-if="showIcon" :class="{ 'rotate-90': open }" name="chevron_right" class="transform transition-all duration-300 ease-in-out w-5 ml-3" />
        </div>

        <div
          class="relative transition-all max-h-0 duration-300 rounded-b-10"
          :class="[...paddingClasses, scroll ? 'overflow-auto' : 'overflow-hidden']"
          ref="container"
          v-bind:style="open === 1 ? 'max-height: ' + height + 'px' : ''"
        >
          <slot name="content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseCard',
  props: {
    title: String,
    paddingClasses: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: 1,
      interval: null,
      height: 0,
      tryCount: 0,
    };
  },
  created() {
    this.open = this.value ? 1 : 0;
  },
  mounted() {
    this.interval = setInterval(() => {
      this.calculateHeight();
    }, 100);
  },
  beforeUnmount() {
    this.clearInterval();
  },
  methods: {
    toggle() {
      this.open = this.open !== 1 ? 1 : null;
      this.$emit('change', this.open);
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    calculateHeight() {
      if (!this.$refs?.container?.scrollHeight) {
        if (this.tryCount >= 10) {
          this.clearInterval();
        }
        return;
      }
      if (this.height !== this.$refs.container.scrollHeight) {
        this.height = this.$refs.container.scrollHeight;
        this.tryCount = 0;
      }
    },
  },
  watch: {
    value() {
      this.open = this.value ? 1 : 0;
    },
  },
};
</script>
