<template>
  <div>
    <div class="text-primary font-500 text-22">Contact Us</div>
    <div class="pt-3">Fill in the form and get a consultation from our experienced Partner.</div>
    <ValidationObserver v-slot="{ validate, errors }">
      <div>
        <FormInput v-model="name" label="Name" class="mt-2" name="name" :error="errors.name" />
        <FormInput label="Email" class="mt-2" name="email" :error="errors.email" />
        <FormTextarea label="Comment" class="mt-2" name="comment" :error="errors.comment" />
        <div class="mt-2">
          <Checkbox class="flex-shrink-0 mr-2">
            <div class="text-12">I acknowledge that I have read and understood Fidsy <a href="" target="_blank">Privacy Policy</a> and agree to be contacted by authorized Fidsy partners</div>
          </Checkbox>
        </div>
        <div class="flex mt-4">
          <PillButton text="Submit" class="ml-auto" primary @click="onSubmit(validate)" />
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      name: null,
    };
  },
  methods: {
    async onSubmit(validate) {
      const valid = await validate();
      if (valid.valid) {
        // eslint-disable-next-line
        console.log('TODO');
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
