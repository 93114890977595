<template>
  <div class="relative" v-outside-click="toggleExpand" ref="container">
    <div
      v-if="variant === 'text'"
      class="flex cursor-pointer select-none items-center pl-[6px] py-0.5 pr-0.5 rounded-4 hover:bg-semi-dark-text hover:bg-opacity-10 hover:text-semi-dark-text"
      :class="{ 'bg-semi-dark-text bg-opacity-10 text-semi-dark-text': expand }"
      @click="onButtonClick"
    >
      <span>{{ buttonText }}</span>
      <Icon name="chevron_right" class="w-4 h-4 transform ml-1 transition-all ease-in duration-100" :class="{ '-rotate-90': expand, 'rotate-90': !expand }" />
    </div>
    <PillButton
      v-else
      :disabled="disabled"
      class="capitalize truncate"
      :class="buttonClass"
      elevated
      ref="button"
      :text="buttonText"
      icon="unfold_more"
      icon-class="order-1 -mr-[4px] ml-1 h-5"
      @click="onButtonClick"
    ></PillButton>
    <div v-if="list.length" class="fixed z-30 left-0 flex mt-1 select-none" ref="items-container">
      <div v-if="expand && visible" class="max-h-200 overflow-y-auto shadow-lg rounded-[8px]">
        <div class="bg-white p-2 mr-auto min-w-150">
          <template v-if="list.length">
            <div
              v-for="(item, key) in list"
              :key="key"
              @click="onItemClick(item)"
              class="px-2 py-1 mt-0.5 cursor-pointer rounded-md hover:bg-gray-300 whitespace-nowrap relative"
              :class="{ 'bg-gray-200': isSelected(item) }"
            >
              <template v-if="isObject(item)">{{ item.label }}</template>
              <template v-else>{{ item }}</template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from '@/helpers/debounce';

export default {
  name: 'ButtonDropdown',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selectedListItem: [String, Number, Object],
    buttonClass: {
      type: String,
      default: '',
    },
    disabled: Boolean,
    variant: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: [String, Number, Object],
    },
  },
  data() {
    return {
      expand: false,
      intersected: true,
      visible: true,
      observer: null,
    };
  },
  mounted() {
    document.addEventListener('scroll', this.onPageScroll, true);

    // Register IntersectionObserver
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      this.visible = entry && entry.isIntersecting;
    }, options);

    // this.observer.observe(this.$refs.button.$el);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onPageScroll, true);

    this.observer.disconnect();
  },
  computed: {
    buttonText() {
      if (this.modelValue) {
        return this.list.find((item) => item.value === this.modelValue)?.label || null;
      }
      if (this.isObject(this.selectedListItem)) {
        return this.selectedListItem.label;
      }
      if (this.selectedListItem) {
        return this.selectedListItem;
      }
      return '';
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    onPageScroll: debounce(function () {
      this.listContainerPosition();
    }, 50),
    isObject(item) {
      return item && typeof item === 'object';
    },
    isSelected(item) {
      if (this.isObject(item)) {
        return this.selectedListItem?.value === item.value;
      }
      return this.selectedListItem === item;
    },
    onButtonClick($event) {
      this.expand = !this.expand;
      this.listContainerPosition();
      this.$emit('click', $event);
    },
    listContainerPosition() {
      if (this.list?.length) {
        const containerViewportOffset = this.$refs.container.getBoundingClientRect();
        const { top, height } = containerViewportOffset;
        let { left } = containerViewportOffset;

        this.$refs['items-container'].style.left = `${left}px`;
        this.$refs['items-container'].style.top = `${top + height + 2}px`;

        // Check list overflowing
        this.$nextTick(() => {
          let rePos = false;
          const itemContainerViewportOffset = this.$refs['items-container'].getBoundingClientRect();
          const { width } = itemContainerViewportOffset;
          if (left + width > window.innerWidth) {
            left = window.innerWidth - width;
            rePos = true;
          }

          // Repositioning
          if (rePos) {
            this.$refs['items-container'].style.left = `${left}px`;
            this.$refs['items-container'].style.top = `${top + height + 2}px`;
          }
        });
      }
    },
    toggleExpand() {
      this.expand = false;
    },
    onItemClick(item) {
      this.$emit('update:modelValue', item.value);
      this.expand = !this.expand;
    },
  },
};
</script>

<style scoped></style>
