<template>
  <div :class="{ 'overflow-hidden': appendToBody }" @click="$emit('click', $event)">
    <FormLabel :label="label" :error="!!error" />
    <v-select
      :id="id"
      ref="vselect"
      class="w-full"
      :style="{ '--vs-border-color': !!error ? 'var(--color-alert)' : '' }"
      :disabled="disabled"
      :model-value="modelValue"
      :options="options"
      :clearable="clearable"
      :searchable="searchable"
      :reduce="reduce"
      :placeholder="placeholder"
      :appendToBody="appendToBody"
      :outlined="outlined || null"
      :rounded="rounded || null"
      :elevated="elevated || null"
      :dense="dense || null"
      :multiple="multiple"
      @update:modelValue="updateValue"
      @blur="onBlur"
      @keyup="onKeyup"
      @option:selected="$emit('option:selected', $event)"
    >
      <template v-slot:list-footer>
        <div
          v-if="showAddButton"
          style="bottom: -1px"
          class="flex sticky justify-center w-full cursor-pointer py-2 bg-gray-100 text-secondary hover:text-primary border-t border-solid border-gray-200"
          @click="$emit('add')"
        >
          {{ $t('add_new') }}
        </div>
        <slot v-else name="list-footer"></slot>
      </template>
    </v-select>

    <label v-if="error || $slots['error']" class="text-12 text-alert mt-3 mb-1">{{ error }} <slot name="error"></slot></label>
  </div>
</template>

<script>
import ValidationMixin from '../../mixins/ValidationMixin';
import FormLabel from './FormLabel.vue';

export default {
  name: 'FormSelect',
  components: { FormLabel },
  mixins: [ValidationMixin],
  emits: ['click', 'update:modelValue', 'option:selected', 'blur', 'keyup', 'add'],
  props: {
    disabled: Boolean,
    modelValue: {
      type: [String, Number, Object, Array],
    },
    options: Array,
    clearable: Boolean,
    searchable: Boolean,
    appendToBody: Boolean,
    reduce: Function,
    label: String,
    error: String,
    placeholder: String,
    elevated: Boolean,
    outlined: Boolean,
    rounded: Boolean,
    dense: Boolean,
    id: String,
    multiple: Boolean,
    showAddButton: Boolean,
  },
  methods: {
    updateValue(value) {
      this.eventbus.emit('input', value);
      this.$emit('update:modelValue', value);
    },
    onBlur($event) {
      this.eventbus.emit('blur', $event);
      this.$emit('blur', $event);
    },
    onKeyup($event) {
      this.eventbus.emit('keyup', $event);
      this.$emit('keyup', $event);
    },
  },
};
</script>
