<template>
  <div class="h-full flex flex-col">
    <div class="flex w-full pb-4 items-center flex-shrink-0 pl-2">
      <div class="ml-auto flex flex-row justify-center">
        <v-select
          v-model="language"
          dense
          :options="selectLanguages"
          :reduce="(option) => option.value"
          auto-select
          rounded
          elevated
          :clearable="false"
          :searchable="false"
          alternative-indicator
          class="mr-3 w-[120px]"
          @update:modelValue="onLanguageChange"
        />

        <PillButton class="ml-auto" :loading="saving" :disabled="saving" :text="$t('save')" icon="save" text-class="max-sm:hidden" icon-class="max-sm:mr-0" primary @click="save" />
      </div>
    </div>
    <div class="w-full pr-3 flex-grow overflow-auto mb-5">
      <div class="h-full flex flex-col">
        <Draggable
          v-model="suggestions[language]"
          itemKey="id"
          v-bind="dragOptions"
          :component-data="{ tag: 'div', type: 'transition-group', name: !drag ? 'flip-list' : null }"
          @start="drag = true"
          @end="drag = false"
        >
          <template #item="{ element: item, index }">
            <div class="flex items-center">
              <div class="suggestion-drag-handle mr-2 cursor-grab"><Icon name="drag_indicator" class="w-4 h-4" /></div>
              <Input :modelValue="item.text" class="mb-1 flex-grow" @update:modelValue="onSuggestionInput($event, index)" @paste="onInputPaste($event, index)" />
              <IconButton :class="{ invisible: index + 1 === suggestions.length && item.text === '' }" class="text-primary" icon="delete" size="4" @click="onRemoveSuggestionClicked(index)" />
            </div>
          </template>
        </Draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import uuidv4 from '@/helpers/uuid';

export default {
  name: 'ModelSuggestions',
  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      language: 'TR',
      suggestions: {},
      drag: false,
      saving: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId', 'availableDataSources', 'user', 'designTimeLanguage', 'org']),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'drag-ghost',
      };
    },
    payload() {
      const suggestions = structuredClone(this.suggestions);
      Object.keys(suggestions).forEach((lang) => {
        suggestions[lang] = suggestions[lang].map((phrase) => phrase.text.trim()).filter((item) => item);
      });
      return suggestions;
    },
    languages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: lang }));
    },
    selectLanguages() {
      return ['EN', 'TR'].map((lang) => ({ value: lang, label: this.$t(`languages.${lang}`) }));
    },
    projectId() {
      return this.$route.params.projectId;
    },
    modelId() {
      return this.$route.params.modelId;
    },
  },
  created() {
    this.language = this.user?.language || 'EN';
    if (this.languages.some((lang) => lang.value === this.designTimeLanguage)) {
      this.language = this.designTimeLanguage;
    }
    this.fetchData();
  },
  methods: {
    ...mapActions(['showToastMessage', 'updateProjectModel']),
    ...mapMutations(['SET_DESIGN_TIME_LANGUAGE']),
    onLanguageChange(lang) {
      this.SET_DESIGN_TIME_LANGUAGE(lang);
    },
    onInputPaste(e, index) {
      const text = e.clipboardData.getData('Text');
      const phrases = text
        .split(/(?:\r\n|\r|\n)/g)
        // .map((i) => this.formatPhrase(i))
        .filter((i) => i);

      phrases.forEach((phrase, i) => {
        this.onSuggestionInput(phrase, index + i);
      });
      e.preventDefault();
    },
    formatPhrase(phrase) {
      phrase = phrase.replace(/\s+/g, ' ');
      phrase = phrase.replace(/\.+/g, '');
      phrase = phrase.replace(/[^\p{L}0-9'\-\s]/gu, '');
      phrase = phrase.toLocaleLowerCase();
      return phrase;
    },
    onSuggestionInput(value, key) {
      this.suggestions[this.language][key].text = value;
      this.checkSuggestionsForNew();
    },
    onAddSuggestionClicked() {
      this.suggestions[this.language].push({ id: uuidv4(), text: '' });
    },
    checkSuggestionsForNew() {
      if (this.suggestions[this.language].length) {
        if (this.suggestions[this.language][this.suggestions[this.language].length - 1].text !== '') {
          this.onAddSuggestionClicked();
        }
      } else {
        this.onAddSuggestionClicked();
      }
    },
    onRemoveSuggestionClicked(index) {
      if (index >= 0) {
        this.suggestions[this.language].splice(index, 1);
      }
    },
    async save() {
      this.saving = true;
      await this.updateProjectModel({
        project_id: this.projectId,
        model_id: this.modelId,
        name: this.model.name,
        type: this.model.type,
        finetunedata: this.model.finetunedata,
        suggestions: this.payload,
      });
      this.saving = false;
    },
    async fetchData() {
      const suggestions = structuredClone(this.model.suggestions);
      this.selectLanguages.forEach((item) => {
        const lang = item.value;
        let value = [];
        if (lang in suggestions) {
          value = suggestions[lang].map((text) => ({ id: uuidv4(), text }));
        }
        this.suggestions[lang] = value;
      });
      this.checkSuggestionsForNew();
    },
  },
  watch: {
    language() {
      if (!this.loading) {
        this.checkSuggestionsForNew();
      }
    },
  },
};
</script>
<style>
.drag-ghost {
  opacity: 0.5;
}
</style>
