<template>
  <Modal
    sheetbelowsm
    :title="$t('projects.flows.add')"
    :primary-button="$t('save')"
    :primary-button-disabled="!isIntentNameValid()"
    :primary-button-loading="loading"
    :secondary-button="$t('cancel')"
    close-button
    @primary="onSaveClicked"
    @secondary="onCancelClicked"
    @close="onCancelClicked"
  >
    <ValidationObserver ref="validationObserver" v-slot="{ errors }">
      <div class="py-5 w-full sm:w-600 px-10">
        <div class="w-full flex mb-3">
          <FormInput v-model="name" name="name" class="w-full" :label="$t('name')" :rules="['required', { validate: isIntentNameValid }]" :error="errors.name"></FormInput>
        </div>
      </div>
    </ValidationObserver>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { addNewStep } from '@/helpers/flow';

export default {
  name: 'CreateProjectFlow',
  data() {
    return {
      originalName: '',
      name: '',
      loading: false,
    };
  },
  computed: {
    ...mapState(['projectData']),
    projectId() {
      return this.$route.params.projectId;
    },
    flowNames() {
      const data = this.projectData?.[this.projectId]?.flows || {};
      return Object.keys(data);
    },
  },
  methods: {
    ...mapActions(['showToastMessage', 'createProjectFlow']),
    isIntentNameValid() {
      if (this.flowNames.includes(this.name)) {
        return this.$t('validation.name_unique');
      }
      return true;
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      const valid = await this.$refs.validationObserver.validate();
      if (valid.valid) {
        this.loading = true;

        const steps = [];
        addNewStep(steps, { column: 1 });
        const response = await this.createProjectFlow({ project_id: this.projectId, name: this.name, steps, transition: steps[0].id });
        if (response) {
          this.$emit('close');
        }
        this.loading = false;
      }
    },
  },
};
</script>
