<template>
  <div class="px-3 pb-2">
    <div class="mb-2 mt-3">
      <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.text') }}</div>
      <Input v-model="text" class="mb-1 w-full" @update:modelValue="emitPayload" />
    </div>

    <label class="block text-12 mb-1 mt-3">{{ $t('evaluate.capture_type') }}</label>
    <div class="flex">
      <RadioButton v-model="captureType" value="prompt" name="captureType" :label="$t('evaluate.prompt')" class="mt-1 mr-7" />
      <RadioButton v-model="captureType" value="listen" name="captureType" :label="$t('evaluate.listen')" class="mt-1" />
    </div>

    <div v-if="captureType === 'prompt'" class="pt-5">
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.store_prompt_response') }}</div>
        <VariableSelect v-model="captureVariable" :filter="promptVariableSelectFilter" class="w-full" @update:modelValue="emitPayload" />
      </div>
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.direction') }}</div>
        <v-select
          v-model="prompt.direction"
          :placeholder="$t('evaluate.select_direction')"
          class="bg-white"
          :options="directionOptions"
          :clearable="true"
          :searchable="false"
          :reduce="selectReducer"
          @update:modelValue="emitPayload"
        ></v-select>
      </div>
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.items_datatable') }}</div>
        <VariableSelect v-model="prompt.items" selectBgColor="bg-white" :filter-data-type="['DataTable']" :showCreate="false" @update:modelValue="emitPayload" />
      </div>
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.label_column') }}</div>
        <Input v-model="prompt.labelColumn" class="mb-1 w-full" @update:modelValue="emitPayload" />
      </div>
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.value_column') }}</div>
        <Input v-model="prompt.valueColumn" class="mb-1 w-full" @update:modelValue="emitPayload" />
      </div>
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.description_column') }}</div>
        <Input v-model="prompt.descriptionColumn" class="mb-1 w-full" @update:modelValue="emitPayload" />
      </div>
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.image_url_column') }}</div>
        <Input v-model="prompt.imageUrlColumn" class="mb-1 w-full" @update:modelValue="emitPayload" />
      </div>
      <div class="mb-2">
        <div class="text-gray-700 text-13 mb-1">{{ $t('evaluate.image_alt_column') }}</div>
        <Input v-model="prompt.imageAltTextColumn" class="mb-1 w-full" @update:modelValue="emitPayload" />
      </div>
    </div>
    <div v-if="captureType === 'listen'" class="pt-5">
      <div v-for="(variable, index) in variables" :key="index" class="flex justify-between items-center py-1">
        <VariableSelect v-model="variables[index]" :filter="variableSelectFilter" :excludes="getExcludedVariables(variables[index])" @update:modelValue="onCaptureVariableSelected" />
        <IconButton class="ml-4 text-primary" icon="delete" size="4" @click="onRemoveClicked(index)" />
      </div>
      <div class="text-right px-4 py-2">
        <PillButton :text="$t('add')" primary @click="onAddClicked" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from '@/helpers/debounce';

export default {
  name: 'EvaluateSetCapture',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      variables: [],
      text: '',
      captureType: 'listen',
      prompt: {
        direction: '',
        items: '',
        labelColumn: '',
        valueColumn: '',
        descriptionColumn: '',
        imageUrlColumn: '',
        imageAltTextColumn: '',
      },
      captureVariable: '',
      directionOptions: [
        {
          label: 'Vertical',
          value: 'vertical',
        },
        {
          label: 'Horizontal',
          value: 'horizontal',
        },
      ],
    };
  },
  computed: {
    ...mapState(['selectedIntentDefinition']),
    ...mapGetters(['getProjectDataTypes']),
    payload() {
      let variables;
      if (this.captureType === 'listen') {
        variables = this.variables
          .filter((v) => v)
          .map((v) => ({
            variable: v,
          }));
      } else {
        variables = [{ variable: this.captureVariable, value: this.prompt }];
      }
      return {
        id: this.item.id,
        type: this.item.type,
        payload: {
          captureType: this.captureType,
          text: this.text,
          variables,
        },
      };
    },
  },
  methods: {
    // eslint-disable-next-line
    emitPayload: debounce(function () {
      this.$emit('update', this.payload);
    }, 300),
    onCaptureVariableSelected() {
      this.emitPayload();
    },
    promptVariableSelectFilter(variable) {
      // Only capture list
      return this.getProjectDataTypes()?.[variable.dataType]?.capture === true || (variable.dataType === 'DataTable' && !variable.readonly);
    },
    variableSelectFilter(variable) {
      // Only capture list
      return this.getProjectDataTypes()?.[variable.dataType]?.capture === true;
    },
    getExcludedVariables() {
      return this.variables.filter((v) => v !== this.variables.some((variable) => variable === v));
    },
    onAddClicked() {
      this.variables.push('');
      this.emitPayload();
    },
    onRemoveClicked(index) {
      this.variables.splice(index, 1);
      this.emitPayload();
    },
    selectReducer(option) {
      return option.value;
    },
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.text = this.item?.payload.text;
          if (this.item.payload?.captureType === 'prompt') {
            const { direction, items, labelColumn, valueColumn, descriptionColumn, imageUrlColumn, imageAltTextColumn } = this.item?.payload?.variables?.[0]?.value;
            this.captureVariable = this.item?.payload?.variables?.[0]?.variable || '';
            this.captureType = 'prompt';
            this.prompt = {
              direction,
              items,
              labelColumn,
              valueColumn,
              descriptionColumn,
              imageUrlColumn,
              imageAltTextColumn,
            };
          } else {
            const variables = this.item.payload?.variables?.map((v) => v.variable) || [];
            this.variables = [...variables];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
