<template>
  <div>
    <label :class="{ 'text-alert': !!error, 'text-gray-600': !error }" class="text-12 mb-1">{{ label }}</label>
    <Input
      :id="id"
      :class="{ '!border-alert': !!error }"
      class="w-full"
      :type="type"
      :disabled="disabled"
      :modelValue="value"
      :label="label"
      :error="error"
      @update:modelValue="updateValue"
      @blur="$emit('blur', $event)"
      @keyup="$emit('keyup', $event)"
    />
    <div v-if="showPasswordBar" class="form-password-strength-bar" :class="passwordBarClass"></div>
    <label v-if="error || $slots['error']" class="text-12 text-alert mt-3 mb-1">{{ error }} <slot name="error"></slot></label>
  </div>
</template>

<script>
import ValidationMixin from '../../mixins/ValidationMixin';

const commonPasswords = [
  '123456',
  'qwerty',
  'password',
  '111111',
  'Abc123',
  '123456789',
  '12345678',
  '123123',
  '1234567890',
  '12345',
  '1234567',
  'qwertyuiop',
  'qwerty123',
  '1q2w3e',
  'password1',
  '123321',
  'Iloveyou',
  '12345',
];

export default {
  name: 'FormPassword',
  mixins: [ValidationMixin],
  emits: ['update:modelValue', 'blur', 'keyup'],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: String,
    error: String,
    id: String,
    showPasswordBar: Boolean,
  },
  computed: {
    value() {
      return this.modelValue;
    },
    passwordBarClass() {
      if (!this.value) {
        return null;
      }

      const STRENGTH_MAP = {
        0: 'risky',
        1: 'guessable',
        2: 'weak',
        3: 'safe',
        4: 'secure',
      };

      const score = this.scorePassword(this.value);
      return {
        [STRENGTH_MAP[score]]: true,
        scored: true,
      };
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
    scorePassword(pass) {
      let score = 0;
      let length = 0;
      let specialChar = 0;
      let caseMix = 0;
      let numCharMix = 0;

      const specialCharRegex = /[^A-Za-z0-9]/g;
      const lowercaseRegex = /(.*[a-z].*)/g;
      const uppercaseRegex = /(.*[A-Z].*)/g;
      const numberRegex = /(.*[0-9].*)/g;
      const repeatCharRegex = /(\w)(\1+\1+\1+\1+)/g;

      const hasSpecialChar = specialCharRegex.test(pass);
      const hasLowerCase = lowercaseRegex.test(pass);
      const hasUpperCase = uppercaseRegex.test(pass);
      const hasNumber = numberRegex.test(pass);
      const hasRepeatChars = repeatCharRegex.test(pass);

      if (pass.length > 4) {
        if (this.isCommonPassword(pass)) {
          return 0;
        }

        if ((hasLowerCase || hasUpperCase) && hasNumber) {
          numCharMix = 1;
        }

        if (hasUpperCase && hasLowerCase) {
          caseMix = 1;
        }

        if ((hasLowerCase || hasUpperCase || hasNumber) && hasSpecialChar) {
          specialChar = 1;
        }

        if (pass.length > 8) {
          length = 1;
        }

        if (pass.length > 12 && !hasRepeatChars) {
          length = 2;
        }

        if (pass.length > 20 && !hasRepeatChars) {
          length = 3;
        }

        score = length + specialChar + caseMix + numCharMix;

        if (score > 4) {
          score = 4;
        }
      }

      return score;
    },
    isCommonPassword(password) {
      return commonPasswords.includes(password);
    },
  },
};
</script>

<style lang="scss">
.form-password-strength-bar {
  border-radius: 2px;
  transition: all 0.2s linear;
  height: 5px;
  margin-top: 4px;
}

.form-password-strength-bar.risky {
  background-color: #f95e68;
  width: 10%;
}

.form-password-strength-bar.guessable {
  background-color: #fb964d;
  width: 32.5%;
}

.form-password-strength-bar.weak {
  background-color: #fdd244;
  width: 55%;
}

.form-password-strength-bar.safe {
  background-color: #b0dc53;
  width: 77.5%;
}

.form-password-strength-bar.secure {
  background-color: #35cc62;
  width: 100%;
}
</style>
