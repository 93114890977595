<template>
  <div>
    <ButtonDropdown v-if="false" :modelValue="activeItem" :list="getProjects" @update:modelValue="onChange"> </ButtonDropdown>
    <v-select
      v-if="true"
      outlined
      rounded
      :clearable="false"
      autoSelect
      :searchable="false"
      :modelValue="activeItem"
      :options="getProjects"
      class="ml-2 sm:ml-0 max-md:min-w-[130px] md:min-w-200 flex-grow"
      :reduce="(option) => option.value"
      @update:modelValue="onChange"
    ></v-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NoCodeHeader',
  data() {
    return {
      activeItem: null,
    };
  },
  computed: {
    ...mapState(['projects']),
    getProjects() {
      return Object.values(this.projects).map((project) => ({ label: project.name, value: project.project_id }));
    },
  },
  created() {
    if (this.$route.params.projectId) {
      this.activeItem = this.$route.params.projectId;
    }
  },
  methods: {
    async onChange(id) {
      await this.$router.push({ name: this.$route.name, params: { projectId: id } });
      this.activeItem = id;
    },
  },
};
</script>
