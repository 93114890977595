<template>
  <Modal
    sheetbelowsm
    :title="$t('projects.create')"
    :primary-button="$t('save')"
    :primary-button-disabled="!name.trim() || loading"
    :primary-button-loading="loading"
    :secondary-button-disabled="loading"
    :secondary-button="$t('cancel')"
    close-button
    @primary="onSaveClick"
    @secondary="onCancelClick"
    @close="onCancelClick"
    @opened="onModalOpen"
  >
    <div class="w-full sm:w-700">
      <div class="w-full flex flex-col justify-start mt-3 py-7 px-10">
        <FormInput v-model="name" ref="name" :label="$t('projects.name')" :readonly="loading" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CreateProjectModal',
  data() {
    return {
      loading: false,
      name: '',
    };
  },
  mounted() {
    setTimeout(() => {
      // this.$refs.name.focus();
      // console.log(this.$refs.name);
    }, 1000);
  },
  methods: {
    ...mapActions(['createProject']),
    onModalOpen() {
      this.$refs?.name?.focus();
    },
    async onSaveClick() {
      this.loading = true;
      await this.createProject({ name: this.name.trim() });
      this.loading = false;
      this.$emit('close');
    },
    onCancelClick() {
      this.$emit('close');
    },
  },
};
</script>
