<template>
  <Modal
    sheetbelowsm
    :title="$t('data_types.data_type_relationships')"
    :primary-button="$t('save')"
    :primary-button-disabled="loading || fetching"
    :secondary-button="$t('cancel')"
    :primary-button-loading="loading"
    close-button
    @primary="onSaveClicked"
    @close="onCancelClicked"
    @secondary="onCancelClicked"
  >
    <div class="lg:w-900 min-h-200">
      <div v-if="fetching" class="w-full h-[220px] mx-auto">
        <div class="flex-grow flex items-center justify-center h-full">
          <Spinner class="w-8" />
        </div>
      </div>
      <div v-else class="w-full flex flex-col justify-start mt-5 px-10">
        <div v-for="(field, index) in fields" :key="index" class="flex items-center my-1">
          <div class="flex items-center w-full flex-wrap lg:flex-nowrap mb-1 sm:mb-0 -mt-1 max-lg:pb-5">
            <FormSelect
              v-model="field.left"
              append-to-body
              :options="leftDataTypes"
              :reduce="(opt) => opt.value"
              class="sm:mr-2 max-lg:flex-grow max-lg:w-full w-1/4"
              :placeholder="$t('data_type')"
              @update:modelValue="onFieldInput"
            />
            <FormSelect
              :disabled="!field.left"
              v-model="field.leftProp"
              append-to-body
              :options="leftDataTypeProperties(field.left)"
              :reduce="(opt) => opt.value"
              class="sm:mr-2 max-lg:flex-grow max-lg:w-full w-1/4"
              :placeholder="$t('flow.property')"
              @update:modelValue="onFieldInput"
            />
            <span class="mr-2 max-lg:mx-auto">=</span>
            <FormSelect
              v-model="field.right"
              append-to-body
              :options="rightDataTypes"
              :reduce="(opt) => opt.value"
              class="sm:mr-2 max-lg:flex-grow max-lg:w-full w-1/4"
              :placeholder="$t('data_type')"
              @update:modelValue="onFieldInput"
            />
            <FormSelect
              :disabled="!field.right"
              v-model="field.rightProp"
              append-to-body
              :options="rightDataTypeProperties(field.right)"
              :reduce="(opt) => opt.value"
              class="sm:mr-2 max-lg:flex-grow max-lg:w-full w-1/4"
              :placeholder="$t('flow.property')"
              @update:modelValue="onFieldInput"
            />
          </div>
          <IconButton :class="{ invisible: index + 1 === fields.length }" icon="delete" class="text-primary" :text="$t('delete')" @click="removeField(index)" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { apiGetDataTypeRelationship, apiPutDataTypeRelationship } from '@/helpers/api';

export default {
  name: 'CreateDatatypeRelation',
  props: {
    intent: String,
    intentDetails: Object,
  },
  emits: ['close'],
  data() {
    return {
      fields: [],
      loading: false,
      fetching: false,
    };
  },
  computed: {
    ...mapState(['designTimeActiveDatasourceType', 'designTimeActiveDatasourceModelId']),
    ...mapGetters(['getProjectDataTypes']),
    userDataTypes() {
      const dataTypes = [];
      Object.keys(this.getProjectDataTypes()).forEach((dataType) => {
        if (this.getProjectDataTypes()[dataType]?.fields && this.getProjectDataTypes()[dataType].entityType !== 'system') {
          dataTypes.push({ label: dataType, value: dataType });
        }
      });
      return dataTypes;
    },
    leftDataTypes() {
      return this.userDataTypes;
    },
    rightDataTypes() {
      return this.userDataTypes;
    },
    relationships() {
      return this.fields.map((field) => `${field.left}.${field.leftProp}|${field.right}.${field.rightProp}`).filter((item) => item !== '.|.');
    },
  },
  methods: {
    ...mapActions(['showToastMessage']),
    leftDataTypeProperties(dataType) {
      if (!dataType || !this.getProjectDataTypes()[dataType]?.fields) return [];
      return Object.keys(this.getProjectDataTypes()[dataType].fields).map((item) => ({ label: item, value: item }));
    },
    rightDataTypeProperties(dataType) {
      if (!dataType || !this.getProjectDataTypes()[dataType]?.fields) return [];
      return Object.keys(this.getProjectDataTypes()[dataType].fields).map((item) => ({ label: item, value: item }));
    },
    addField() {
      this.fields.push({ left: '', leftProp: '', right: '', rightProp: '' });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    onCancelClicked() {
      this.$emit('close');
    },
    async onSaveClicked() {
      this.loading = true;
      const response = await apiPutDataTypeRelationship({
        type: this.designTimeActiveDatasourceType,
        model_id: this.designTimeActiveDatasourceModelId,
        relationships: this.relationships,
      });
      this.loading = false;
      if (response.status === 200) {
        this.showToastMessage({ message: this.$t('data_types.relation_updated_successfully'), type: 'success' });
        this.$emit('saveintent', response.data);
        this.$emit('close');
        return;
      }
      this.showToastMessage({ title: this.$t('data_types.relation_failed_to_update'), type: 'error' });
    },
    onFieldInput() {
      this.checkFieldsForNew();
    },
    checkFieldsForNew() {
      if (this.fields.length) {
        if (this.fields[this.fields.length - 1].left !== '') {
          this.addField();
        }
      } else {
        this.addField();
      }
    },
    async fetchData() {
      try {
        this.fetching = true;
        const response = await apiGetDataTypeRelationship({ type: this.designTimeActiveDatasourceType, model_id: this.designTimeActiveDatasourceModelId });
        this.fetching = false;
        if (response.status === 200) {
          response.data.forEach((item) => {
            const [lhs, rhs] = item.split('|');
            const [left, leftProp] = lhs.split('.');
            const [right, rightProp] = rhs.split('.');
            this.fields.push({ left, leftProp, right, rightProp });
          });
          this.checkFieldsForNew();
          return;
        }
        this.showToastMessage({ title: response.data.message || this.$t('data_types.failed_to_get_data_type_relationships'), type: 'error' });
      } catch (e) {
        this.showToastMessage({ title: this.$t('data_types.failed_to_get_data_type_relationships'), type: 'error' });
      }
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    'fields.length': {
      handler() {
        this.checkFieldsForNew();
      },
    },
  },
};
</script>
