<template>
  <div></div>
</template>

<script>
export default {
  name: 'AdminHeader',
  data() {
    return {
      activeItem: null,
    };
  },
};
</script>
